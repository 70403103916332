import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NavigationService} from "../../../shared/services/navigation.service";
import {BffApiService} from "../../../shared/api/bff-api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {UserService} from "../../../shared/services/user.service";

@Component({
  selector: 'app-page-login',
  templateUrl: './page-login.component.html',
  styleUrls: ['../connexion.scss']
})
export class PageLoginComponent {
  loginForm: FormGroup;

  constructor(private fb: FormBuilder,
              private userService: UserService,
              private snack: MatSnackBar,
              public nav: NavigationService) {
    this.loginForm  = this.fb.group({
      email: [null, Validators.required],
      password: [null, Validators.required],
    })
  } // constructor


  submit() {
    if(this.loginForm.invalid) {
      this.snack.open('Le formulaire est faux veuillez verifier les informations entrées')
      return
    }
    this.userService.login(this.loginForm.value)
  }


}
