import { Component } from '@angular/core';
import {NavigationService} from "../../shared/services/navigation.service";

@Component({
  selector: 'app-navigation-bottom',
  templateUrl: './navigation-bottom.component.html',
  styleUrls: ['./navigation-bottom.component.scss']
})
export class NavigationBottomComponent {
  constructor(protected nav: NavigationService) {
  }
}
