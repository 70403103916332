import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {NavigationService} from "../../../shared/services/navigation.service";
import {BffApiService} from "../../../shared/api/bff-api.service";

@Component({
  selector: 'app-check-email',
  standalone: true,
  imports: [],
  templateUrl: './check-email.component.html',
  styleUrl: './check-email.component.scss'
})
export class CheckEmailComponent implements OnInit {
  email = 'x@example.ch'

  constructor(private route:ActivatedRoute,
              protected nav: NavigationService,
              private  bff: BffApiService) {  }
  ngOnInit(): void {
    this.email = this.route.snapshot.paramMap.get('email') ?? 'x@example.ch';
    this.sendEmail()
  }

  sendEmail() {
    this.bff.sendVerificationEmail().subscribe( data => {
      console.log(data)
      // todo toaster
    })
  }
}
