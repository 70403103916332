import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {finalize, Observable} from 'rxjs';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from "@angular/router";
import {LoaderService} from "./loader.service";

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private totalRequests = 0;
  isInNavigation: boolean = true;
  waitingRequest: boolean = true;

  constructor(private router: Router,
              private loader: LoaderService) {
    this.router.events.subscribe((event: any) => {
      this.navigationInterceptor(event);
    });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.totalRequests++;
    this.waitingRequest = true;
    this.mergeEvents()

    return next.handle(request).pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests == 0) {
          this.waitingRequest = false;
          this.mergeEvents()
        }
      })
    );
  }

  navigationInterceptor(event: any): void {
    if (event instanceof NavigationStart) {
      this.isInNavigation = true;
      this.mergeEvents()
    } else if (    // Set loading state to false in both of the below events to hide the spinner in case a request fails
      event instanceof NavigationEnd ||
      event instanceof NavigationCancel ||
      event instanceof NavigationError
    ) {
      this.isInNavigation = false;
      this.mergeEvents()
    }
  }

  mergeEvents() {
    return this.loader.setLoading(this.isInNavigation || this.waitingRequest)
  }


}

