import { Component } from '@angular/core';
import {CartService} from "../../../shared/services/cart.service";
import {Basket} from "../../../../../../fe-global-components/interfaces/interfaces";

@Component({
  selector: 'app-success-payment',
  templateUrl: './success-payment.component.html',
  styleUrls: ['./success-payment.component.scss']
})
export class SuccessPaymentComponent {
  articles: any;

  titre = 'Paiement réussi'

  cart!: Basket;
  constructor(private cartService: CartService) {
    this.cartService.currentCart.subscribe(currentCart => this.cart = currentCart);
  }

}
