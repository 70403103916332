<main class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
  <div class="text-center">
    <p class="text-base font-semibold text-stone-600">Au revoir</p>
    <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Merci d'être passé</h1>
    <p class="mt-6 text-base leading-7 text-gray-600">Nous sommes ravis de votre visite !</p>
    <div class="mt-10 flex items-center justify-center gap-x-6">
      <a (click)="nav.goToShop()" class="rounded-md bg-stone-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-stone-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600">Boutique</a>
      <a (click)="nav.goToHome()" class="text-sm font-semibold text-gray-900">Page d'accueil <span aria-hidden="true">&rarr;</span></a>
    </div>
  </div>
</main>
