import { Injectable } from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  constructor(private _snackBar: MatSnackBar) { }

  public snack(mgs: string) {
    this._snackBar.open(mgs, 'ok', {
     // duration: 3 * 1000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      panelClass: ['green-snackbar'],
    });
  }


}


export const  successSubscription: string = "Votre inscription est bien prise en compte. Vous recevrez de nos nouvelles bientôt!";
export const  successDeleteAccount: string = "Votre conmpte et toute vos données ont été supprimé.";
export const  successLogout: string = "Vous êtes déconnecté";
export const  successLogin: string = "'Vous êtes connecté'";
export const  wrongEmailAdress: string = "Désolé, cette adresse est déjà utilisée, essayer avec une autre adresse email.";
export const  errorGeneral: string = "Désolé, une erreur est survenue. Si le problème persiste, contactez le support.";
