<main class="bg-stone-100">
  <section class="relative isolate overflow-hidden bg-gray-200 py-24 sm:py-32 pt-44 mt-20">
    <img src="/assets/img/header-1.jpeg" alt="" class="absolute brightness-75 inset-0 -z-10 h-full w-full object-cover background-img">
    <div class="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl" aria-hidden="true">
      <div class="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#63390f] to-[#c29365] opacity-20" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"></div>
    </div>
    <div class="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu" aria-hidden="true">
      <div class="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#63390f] to-[#c29365] opacity-20" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"></div>
    </div>
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:mx-0">
        <p class="text-5xl font-normal text-white">
          <img src="assets/svg/logo.svg" class="h-28 invert inline w-auto">
          <span class="align-bottom">Vestra</span>
        </p>
        <p class="mt-6 text-lg leading-8 text-white">Achète sur Vestra et valorise les articles de sports invendus !</p>
        <div class="mt-10 flex items-center gap-x-6">
          <a href="/shop" (click)="nav.goToShop(); nav.preventDefault($event)" class="rounded-md bg-stone-700 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-amber-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600">Voir les offres</a>
          <!--              <a href="#" class="text-sm font-semibold leading-6 text-gray-900">S'inscrire <span aria-hidden="true">→</span></a>-->
        </div>
      </div>
    </div>
  </section>



  <section class="relative isolate">
    <div class="overflow-hidden">
      <div class="mx-auto max-w-7xl px-6 pb-32 lg:px-8">
        <!--        <div class="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center bg-img">-->
        <!--          <div class="bg-white m-6 p-12 w-full max-w-xl lg:shrink-0 xl:max-w-2xl">-->
        <!--            <h1 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Vestra, l'achat intelligent ?</h1>-->
        <!--            <p class="relative mt-6 text-sm leading-6 text-gray-600 sm:max-w-md lg:max-w-none">Contribue à la réduction du gaspillage dans le monde du sport en permettant une vie aux articles invendus de ton magasin local !</p>-->
        <!--            <div class="mt-10 flex items-center gap-x-6">-->
        <!--              <a href="#" class="rounded-md bg-stone-700 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-amber-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600">Voir les offres</a>-->
        <!--&lt;!&ndash;              <a href="#" class="text-sm font-semibold leading-6 text-gray-900">S'inscrire <span aria-hidden="true">→</span></a>&ndash;&gt;-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->


        <!-- TODO logo -->
<!--        <div class="py-12 sm:py-6">-->
<!--          <div class="mx-auto max-w-7xl px-6 lg:px-8">-->
<!--            <div class="mx-auto grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-7">-->
<!--              <img class="col-span-2 max-h-8 w-full object-contain lg:col-span-1" src="https://tailwindui.com/img/logos/158x48/transistor-logo-gray-900.svg" alt="Transistor" width="158" height="48">-->
<!--              <img class="col-span-2 max-h-8 w-full object-contain lg:col-span-1" src="https://tailwindui.com/img/logos/158x48/tuple-logo-gray-900.svg" alt="Tuple" width="158" height="48">-->
<!--              <img class="col-span-2 max-h-8 w-full object-contain lg:col-span-1" src="https://tailwindui.com/img/logos/158x48/reform-logo-gray-900.svg" alt="Reform" width="158" height="48">-->
<!--              <img class="col-span-2 max-h-8 w-full object-contain lg:col-span-1" src="https://tailwindui.com/img/logos/158x48/tuple-logo-gray-900.svg" alt="Tuple" width="158" height="48">-->
<!--              <img class="col-span-2 max-h-8 w-full object-contain lg:col-span-1" src="https://tailwindui.com/img/logos/158x48/tuple-logo-gray-900.svg" alt="Tuple" width="158" height="48">-->
<!--              <img class="col-span-2 max-h-8 w-full object-contain lg:col-span-1" src="https://tailwindui.com/img/logos/158x48/savvycal-logo-gray-900.svg" alt="SavvyCal" width="158" height="48">-->
<!--              <img class="hidden lg:block  col-span-2 col-start-2 max-h-8 w-full object-contain sm:col-start-auto lg:col-span-1" src="https://tailwindui.com/img/logos/158x48/statamic-logo-gray-900.svg" alt="Statamic" width="158" height="48">-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </section>
  <section class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto grid max-w-lg grid-cols-1 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:gap-x-4 sm:gap-y-14 lg:mx-0 lg:max-w-none">
      <div class="mb-4">
        <span>Découvrez</span>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Offres quotidiennes jusqu'à 75% de réduction</h2>
      </div>
    </div>
    <div class="mx-auto grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-4 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-6">
      <app-preview-article class="col-span-2 w-full lg:col-span-1"
                           (click)="openDialog(product)"
                           *ngFor="let product of products | slice:0:6; let i=index"
                           [index]="i+1" [product]="product"></app-preview-article>
    </div>
  </section>

  <section class="pt-24">
    <div class="mx-auto max-w-7xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
      <div class="sm:flex sm:items-baseline sm:justify-between">
        <h2 class="text-2xl font-bold tracking-tight text-gray-900">Découvrir par catégorie</h2>
        <a (click)="nav.goToShop()" class="cursor-pointer hidden text-sm font-semibold text-stone-600 hover:text-stone-500 sm:block">
          Parcourir toutes les catégories
          <span aria-hidden="true"> &rarr;</span>
        </a>
      </div>

      <div class="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:grid-rows-2 sm:gap-x-6 lg:gap-8">
        <div class="group aspect-h-1 aspect-w-2 overflow-hidden rounded-lg sm:aspect-h-1 sm:aspect-w-1 sm:row-span-2">
          <img src="/assets/img/boxed-water-is-better-1NCcWi24FRs-unsplash.jpg" alt="Two models wearing women's black cotton crewneck tee and off-white cotton crewneck tee." class="object-cover object-center group-hover:opacity-75">
          <div aria-hidden="true" class="bg-gradient-to-b from-transparent to-black opacity-50"></div>
          <div class="flex items-end p-6">
            <div>
              <h3 class="font-semibold text-white">
                <a (click)="nav.goToShop('Nouveaux produits')" class="cursor-pointer" >
                  <span class="absolute inset-0"></span>
                  Nouveaux produits
                </a>
              </h3>
              <p aria-hidden="true" class="mt-1 text-sm text-white">Voir</p>
            </div>
          </div>
        </div>
        <div class="group aspect-h-1 aspect-w-2 overflow-hidden rounded-lg sm:aspect-none sm:relative sm:h-full">
          <img src="/assets/img/karsten-winegeart-8-OD8TMEjRA-unsplash.jpg" alt="Wooden shelf with gray and olive drab green baseball caps, next to wooden clothes hanger with sweaters." class="object-cover object-center group-hover:opacity-75 sm:absolute sm:inset-0 sm:h-full sm:w-full">
          <div aria-hidden="true" class="bg-gradient-to-b from-transparent to-black opacity-50 sm:absolute sm:inset-0"></div>
          <div class="flex items-end p-6 sm:absolute sm:inset-0">
            <div>
              <h3 class="font-semibold text-white">
                <a (click)="nav.goToShop('Accessoires')" class="cursor-pointer">
                  <span class="absolute inset-0"></span>
                  Accessoires
                </a>
              </h3>
              <p aria-hidden="true" class="mt-1 text-sm text-white">Voir</p>
            </div>
          </div>
        </div>
        <div class="group aspect-h-1 aspect-w-2 overflow-hidden rounded-lg sm:aspect-none sm:relative sm:h-full">
          <img src="assets/img/chris-boese-KGXMWyqYzng-unsplash.jpg" alt="Walnut desk organizer set with white modular trays, next to porcelain mug on wooden desk." class="object-cover object-center group-hover:opacity-75 sm:absolute sm:inset-0 sm:h-full sm:w-full">
          <div aria-hidden="true" class="bg-gradient-to-b from-transparent to-black opacity-50 sm:absolute sm:inset-0"></div>
          <div class="flex items-end p-6 sm:absolute sm:inset-0">
            <div>
              <h3 class="font-semibold text-white">
                <a (click)="nav.goToShop('Ski')" class="cursor-pointer">
                  <span class="absolute inset-0"></span>
                  Ski
                </a>
              </h3>
              <p aria-hidden="true" class="mt-1 text-sm text-white">Voir</p>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-6 sm:hidden">
        <a (click)="nav.goToShop()" class="cursor-pointer block text-sm font-semibold text-stone-600 hover:text-stone-500">
          Parcourir toutes les catégories
          <span aria-hidden="true"> &rarr;</span>
        </a>
      </div>
    </div>
  </section>


  <!-- Stats -->
  <div class="py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
        <div class="lg:pr-4">
          <div class="relative overflow-hidden rounded-3xl bg-gray-900 px-6 pb-9 pt-64 shadow-2xl sm:px-12 lg:max-w-lg lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
            <img class="absolute inset-0 h-full w-full object-cover brightness-125 saturate-0" src="assets/img/christian-buehner-JQFHdpOKz2k-unsplash.jpg" alt="">
            <div class="absolute inset-0 bg-stone-700 mix-blend-multiply"></div>
            <div class="absolute left-1/2 top-1/2 -ml-16 -translate-x-1/2 -translate-y-1/2 transform-gpu blur-3xl" aria-hidden="true">
              <div class="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#57534e] to-[#a8a29e] opacity-40" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"></div>
            </div>
            <figure class="relative isolate">
              <svg viewBox="0 0 162 128" fill="none" aria-hidden="true" class="absolute -left-2 -top-4 -z-10 h-32 stroke-white/20">
                <path id="0ef284b8-28c2-426e-9442-8655d393522e" d="M65.5697 118.507L65.8918 118.89C68.9503 116.314 71.367 113.253 73.1386 109.71C74.9162 106.155 75.8027 102.28 75.8027 98.0919C75.8027 94.237 75.16 90.6155 73.8708 87.2314C72.5851 83.8565 70.8137 80.9533 68.553 78.5292C66.4529 76.1079 63.9476 74.2482 61.0407 72.9536C58.2795 71.4949 55.276 70.767 52.0386 70.767C48.9935 70.767 46.4686 71.1668 44.4872 71.9924L44.4799 71.9955L44.4726 71.9988C42.7101 72.7999 41.1035 73.6831 39.6544 74.6492C38.2407 75.5916 36.8279 76.455 35.4159 77.2394L35.4047 77.2457L35.3938 77.2525C34.2318 77.9787 32.6713 78.3634 30.6736 78.3634C29.0405 78.3634 27.5131 77.2868 26.1274 74.8257C24.7483 72.2185 24.0519 69.2166 24.0519 65.8071C24.0519 60.0311 25.3782 54.4081 28.0373 48.9335C30.703 43.4454 34.3114 38.345 38.8667 33.6325C43.5812 28.761 49.0045 24.5159 55.1389 20.8979C60.1667 18.0071 65.4966 15.6179 71.1291 13.7305C73.8626 12.8145 75.8027 10.2968 75.8027 7.38572C75.8027 3.6497 72.6341 0.62247 68.8814 1.1527C61.1635 2.2432 53.7398 4.41426 46.6119 7.66522C37.5369 11.6459 29.5729 17.0612 22.7236 23.9105C16.0322 30.6019 10.618 38.4859 6.47981 47.558L6.47976 47.558L6.47682 47.5647C2.4901 56.6544 0.5 66.6148 0.5 77.4391C0.5 84.2996 1.61702 90.7679 3.85425 96.8404L3.8558 96.8445C6.08991 102.749 9.12394 108.02 12.959 112.654L12.959 112.654L12.9646 112.661C16.8027 117.138 21.2829 120.739 26.4034 123.459L26.4033 123.459L26.4144 123.465C31.5505 126.033 37.0873 127.316 43.0178 127.316C47.5035 127.316 51.6783 126.595 55.5376 125.148L55.5376 125.148L55.5477 125.144C59.5516 123.542 63.0052 121.456 65.9019 118.881L65.5697 118.507Z" />
                <use href="#0ef284b8-28c2-426e-9442-8655d393522e" x="86" />
              </svg>
              <img src="/assets/svg/logo.svg" alt="" class="invert h-12 w-auto">
              <blockquote class="mt-6 text-xl font-semibold leading-8 text-white">
                <p>“Chez Vestra, nous croyons en l'alliance entre passion et proximité.
                  C'est pourquoi nous nous efforçons de connecter les amateurs de sport aux commerçants passionnés.
                  Grâce à notre plateforme, nous valorisons les articles de sport invendus et donnons accès à des articles de
                  qualité à des prix attractifs, tout en consommant de manière responsable !”</p>
              </blockquote>
              <figcaption class="mt-6 text-sm leading-6 text-gray-300"><strong class="font-semibold text-white">La team Vestra</strong></figcaption>
            </figure>
          </div>
        </div>
        <div>
          <div class="text-base leading-7 text-gray-700 lg:max-w-lg">
<!--            <p class="text-base font-semibold leading-7 text-stone-600">Plusieurs buts, une vision</p>-->
            <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Identité</h1>
            <div class="max-w-xl">
              <p class="mt-6">Chez Vestra, nous sommes guidés par la mission de réduire le gaspillage en offrant une solution novatrice : une place de marché connectant les magasins de sport aux passionnés. Explorez notre univers où chaque achat devient une expérience à la fois responsable et excitante.</p>
              <p class="mt-8">
                <a (click)="nav.goToShop('shop')" class="text-base font-semibold leading-7 text-stone-600">Rejoignez-nous dans cette aventure ! <span aria-hidden="true">&rarr;</span></a>
              </p>
              <p class="mt-8">Notre plateforme offre une grande diversité d'articles provenant de magasins de sport indépendants en Suisse. Grâce à une équipe motivée composée notamment d’ingénieurs talentueux, nous avons pu créer un espace de vente où les amateurs de sport peuvent trouver leurs articles préférés tout en contribuant à un mode de consommation plus conscient.
              </p>
            </div>
          </div>
          <dl class="mt-10 grid grid-cols-2 gap-8 border-t border-gray-900/10 pt-10 sm:grid-cols-4">
            <div>
              <dt class="text-sm font-semibold leading-6 text-gray-600">Pensé en</dt>
              <dd class="mt-2 text-3xl font-bold leading-10 tracking-tight text-gray-900">2022</dd>
            </div>
            <div>
              <dt class="text-sm font-semibold leading-6 text-gray-600">Créé en </dt>
              <dd class="mt-2 text-3xl font-bold leading-10 tracking-tight text-gray-900">2024</dd>
            </div>
            <div>
              <dt class="text-sm font-semibold leading-6 text-gray-600">Equipe de</dt>
              <dd class="mt-2 text-3xl font-bold leading-10 tracking-tight text-gray-900">3</dd>
            </div>
            <div>
              <dt class="text-sm font-semibold leading-6 text-gray-600">Articles en ligne</dt>
              <dd class="mt-2 text-3xl font-bold leading-10 tracking-tight text-gray-900">{{nbrArticleEnLigne}}</dd>
            </div>
          </dl>

        </div>
      </div>
    </div>
  </div>

</main>


