import {importProvidersFrom, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationTopComponent } from './navigation/navigation-top/navigation-top.component';
import { NavigationBottomComponent } from './navigation/navigation-bottom/navigation-bottom.component';
import { PageHomeComponent } from './page/page-home/page-home.component';
import { PageLoginComponent } from './page/connexion/page-login/page-login.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PageRegisterComponent } from './page/connexion/page-register/page-register.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { PreviewArticleComponent } from './component/article-component/preview-article/preview-article.component';
import { LoadingComponent } from './shared/services/loading/loading-component/loading.component';
import { PageSearchArticleComponent } from './page/page-search-article/page-search-article.component';
import { PageOneArticleComponent } from './component/article-component/page-one-article/page-one-article.component';
import { PageLogoutComponent } from './page/connexion/page-logout/page-logout.component';
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule} from "@angular/common/http";
import { LoadingInterceptor } from "./shared/services/loading/loading.interceptor";
import { CartComponentComponent } from './component/cart-component/cart-component.component';
import { CheckoutFormComponent } from './page/checkout-journey/checkout-form/checkout-form.component';
import { MatSidenavModule } from "@angular/material/sidenav";
import { PageResetPasswordComponent } from './page/connexion/page-reset-password/page-reset-password.component';
import { FilterSearchArticleComponent } from './component/filter-search-article/filter-search-article.component';
import { PlusMinusComponent } from './micro-component/plus-minus/plus-minus.component';
import { MatIconModule } from "@angular/material/icon";
import { SearchCategoryComponent } from './micro-component/search-category/search-category.component';
import { BffApiService } from "./shared/api/bff-api.service";
import { SuccessPaymentComponent } from './page/checkout-journey/success-payment/success-payment.component';
import { FullOrderDetailsComponent } from './page/checkout-journey/full-order-details/full-order-details.component';
import { OverviewOrdersComponent } from './page/checkout-journey/overview-orders/overview-orders.component';
import { ShoppingNavComponent } from './navigation/shopping-nav/shopping-nav.component';
import { ShoppingByCategoryComponent } from './navigation/shopping-by-category/shopping-by-category.component';
import { QuickViewComponent } from './component/article-component/quick-view/quick-view.component';
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBarModule} from "@angular/material/snack-bar";
import { NgForOf, NgIf, NgOptimizedImage } from "@angular/common";
import { ArticleFormComponent } from './component/article-component/article-form/article-form.component';
import { ErrorInterceptor } from "./shared/api/error.interceptor";
import { Page404Component } from './page/page404/page404.component';
import { PagePartenaireComponent } from './page/page-partenaire/page-partenaire.component';
import { PopupComponent } from './component/popup-components/popup/popup.component';
import { ImgCarouselComponent } from './micro-component/img-carousel/img-carousel.component';
import { PageApiComponent } from "./page/page-api/page-api.component";
import { PageCommerceComponent } from "./page/page-support-commerce/page-commerce.component";
import { UserAvatarComponent } from "./micro-component/user-avatar/user-avatar.component";
import { UserDashboardComponent } from "./page/user-related/user-dashboard/user-dashboard.component";
import { UserProfilComponent } from "./page/user-related/user-dashboard-childs/user-profil/user-profil.component";
import {UserSecurityComponent} from "./page/user-related/user-dashboard-childs/user-security/user-security.component";
import {UserDeleteAccountComponent} from "./page/connexion/user-delete-account/user-delete-account.component";
import {TokenRequestsInterceptor} from "./shared/api/token-requests.interceptor";


@NgModule({
  declarations: [
    AppComponent,
    NavigationTopComponent,
    NavigationBottomComponent,
    PreviewArticleComponent,
    LoadingComponent,
    PageRegisterComponent,
    PageHomeComponent,
    PageSearchArticleComponent,
    PageOneArticleComponent,
    PageLogoutComponent,
    PageLoginComponent,
    PageResetPasswordComponent,
    CartComponentComponent,
    CheckoutFormComponent,
    FilterSearchArticleComponent,
    PlusMinusComponent,
    SearchCategoryComponent,
    SuccessPaymentComponent,
    FullOrderDetailsComponent,
    OverviewOrdersComponent,
    ShoppingNavComponent,
    ShoppingByCategoryComponent,
    UserDeleteAccountComponent,
    QuickViewComponent,
    ArticleFormComponent,
    Page404Component,
    PagePartenaireComponent,
    PopupComponent,
    ImgCarouselComponent,
    PageApiComponent,
    PageCommerceComponent,
    UserAvatarComponent,
    UserDashboardComponent,
    UserProfilComponent,
    UserSecurityComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatMenuModule,
    MatSidenavModule,
    MatIconModule,
    HttpClientModule,
    MatDialogModule,
    MatSnackBarModule,
    NgOptimizedImage,
    FormsModule,
    NgForOf,
    NgIf
  ],
  providers: [
    BffApiService,
    {provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true},
    //{provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: TokenRequestsInterceptor, multi: true},
    {
      provide: MatDialogRef,
      useValue: {}
    },
    PreviewArticleComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
