<article class="cursor-pointer group-hover:opacity-75">
  <div class="img-container" class="h-56 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:h-72 xl:h-80">
    <img class="h-full w-full object-cover object-center" src="/assets/img/article/{{product.id! % 4}}.jpg" alt="ARTICLE DE SPORT">
  </div>
  <div>
    <span class="uppercase text-xs truncate block mt-2">{{product.brand}}</span>
    <h1>{{product.name}}</h1>
    <div class="prices">
      <span class="line-through">{{product.old_price}} CHF</span>&nbsp;<span class="new-price">{{product.price}} CHF</span>
    </div>
<!--    <div class="prices">-->
<!--      <span class="old-price line-through"></span>-->
<!--      <span class="material-symbols-outlined">-->
<!--      add_shopping_cart-->
<!--      </span>-->
<!--    </div>-->
  </div>
</article>
