import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NavigationService} from "../../../shared/services/navigation.service";
import {CartService} from "../../../shared/services/cart.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Article, Color, Product, Size} from "../../../../../../fe-global-components/interfaces/interfaces";
import {colors, product, sizes} from "../../../../../../fe-global-components/mockup_data/mockup_data";
import {MatSnackBar} from "@angular/material/snack-bar";



@Component({
  selector: 'app-quick-view',
  templateUrl: './quick-view.component.html',
  animations: []
})
export class QuickViewComponent implements OnInit {
  public article_form: FormGroup  = this.fb.group({
    size: [null, Validators.required],
    color: [null, Validators.required],
  })

  colors: Color[] = colors;
  sizes: Size[] = sizes;

  currentColor: Color = this.colors[0];
  currentSize: Size = this.sizes[1];

  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public nav: NavigationService,
    public dialogRef: MatDialogRef<QuickViewComponent>,
    protected cartService: CartService,
    @Optional()  @Inject(MAT_DIALOG_DATA) public product: Product) {}
  getUniqueColors() {
    const uniqueProductIds: Color[] = [];
    this.product.variants.filter(product => {
      if (!uniqueProductIds.includes(product.color)) {
        uniqueProductIds.push(product.color);
      }
    });
    this.colors = uniqueProductIds;
    this.currentColor = this.colors[0];
  }
  getUniqueSizes() {
    const uniqueProductIds: Size[] = [];
    this.product.variants.filter(product => {
      if (!uniqueProductIds.includes(product.size)) {
        uniqueProductIds.push(product.size);
      }
    });
    this.sizes = uniqueProductIds;
    this.currentSize = this.sizes[1];
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  selectColor(color: Color){
    this.currentColor = color;
  }

  selectSize(size: Size){
    if(size.available){
      this.currentSize = size;
    }
  }

  ngOnInit(): void {
    if (!this.product) {
      this.product = product
    }
    this.getUniqueSizes();
    this.getUniqueColors();
    this.article_form = this.fb.group({
      size: [this.colors[0].name, Validators.required],
      // qty: [null, Validators.required],
      color: [this.sizes[1].name, Validators.required],
    })
  }

  goToArticle() {
    this.onNoClick();
    this.nav.goToArticle(this.product.id)
  }

}
