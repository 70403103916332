import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {from, lastValueFrom} from "rxjs";
import {Injectable} from "@angular/core";
import {BffApiService} from "./bff-api.service";

@Injectable()

export class TokenRequestsInterceptor implements HttpInterceptor {

  constructor(private bff: BffApiService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return from(this.handle(request, next))
  }

  async handle(request: HttpRequest<any>, next: HttpHandler) {
    if(request.method != 'GET') {
      const res = await lastValueFrom(this.bff.getToken());
      console.log(res)
    }
    return lastValueFrom(next.handle(request));
  }

}
