import { Component, Input } from '@angular/core';
import {animate, style, transition, trigger} from "@angular/animations";
import {ShopMenuService} from "../../shared/services/shop-menu.service";

@Component({
  selector: 'app-search-category',
  templateUrl: './search-category.component.html',
  styleUrls: ['./search-category.component.scss'],
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease', style({  opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms ease', style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class SearchCategoryComponent {
  @Input() category: any;

  isOpen: boolean = false;

  constructor(private shopMenu: ShopMenuService) {}

  toggle_category() {
    this.isOpen =! this.isOpen
  }

  toggleFilter(event: Event, category: string, value: any): void {
    const target = event.target as HTMLInputElement;
    if (target.checked) {
      this.shopMenu.addParam(category, value);
    } else {
      this.shopMenu.removeParam(category, value);
    }
    this.shopMenu.requestShopProducts()
  }

}
