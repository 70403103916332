import { Component } from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {NavigationService} from "../../../shared/services/navigation.service";
import {filter} from "rxjs";

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrl: './user-dashboard.component.scss'
})
export class UserDashboardComponent {
  isMenu: boolean = false;
  isOffCanvasMenu: boolean = false;
  isOffCanvasMenuDialog: boolean = false;
  side_links= [
    {active: false, name:"Mes commandes", href:"user/orders", svg_path: "" },
    // {active: true, name:"Mon profile", href:"user/profile", svg_path: ""},
    {active: false, name:"Gérer mon compte", href:"user/security", svg_path: "" },
    // {active: false, name:"Support", href:"dashboard/transactions", svg_path: "" },
  ]

  constructor(protected nav: NavigationService, private router: Router) {
    this.router.events.pipe(filter(val => val instanceof NavigationEnd)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.side_links.forEach(e => e.active = (event.url.includes(e.href)))
      }
    });
  }

  toggleMenu(){
    this.isMenu = !this.isMenu;
  }

  toggleOffCanvasMenu(){
    this.isOffCanvasMenu = !this.isOffCanvasMenu;

    if (this.isOffCanvasMenuDialog){
      setTimeout(() => {
        this.isOffCanvasMenuDialog = !this.isOffCanvasMenuDialog;
      },400)
    } else {
      this.isOffCanvasMenuDialog = !this.isOffCanvasMenuDialog;
    }
  }

  navigate(link: { name: string; svg_path: string; active: boolean; href: string }) {
    console.log(link)
    console.log(this.side_links)
    this.side_links.forEach(e => e.active = (e.href === link.href));
    console.log(this.side_links)
    this.nav.goToLink(link.href)
  }


}
