import { Component } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {PopupComponent} from "../../component/popup-components/popup/popup.component";

@Component({
  selector: 'app-page-partenaire',
  templateUrl: './page-partenaire.component.html',
  styleUrls: ['./page-partenaire.component.scss']
})
export class PagePartenaireComponent {
  login_form: FormGroup  = this.fb.group({
    email: [null, [Validators.required]],
  })

  shops_valais = [
    {city:"Crans-Montana", shops: [
        {name:"Zermatten Sports", address:"Crans-Montana"},
      ],
      expanded: false
    },
    {city:"Martigny", shops: [
        {name:"Look Montagne", address:"Rue du Léman 19, 1920 Martigny", website:"https://www.lookmontagne.ch/"},
      ],expanded: false
    },
    {city:"Sierre", shops: [
        {name:"Zermatten Sports", address:""},
      ],expanded: false
    },
    {city:"Sion", shops: [
        {name:"Follomi Sports", address:""},
      ],expanded: false
    },
  ]

  shops_fribourg = []
  shops_vaud=[
    {city:"Lausanne", shops: [
        {name:"Yosemite Sports", address:""},
      ],expanded: false
    },
    {city:"Vevey", shops: [
        {name:"Yosemite Sports", address:""},
      ],expanded: false
    },
  ]
  cantons = [
    {name:"Valais", shop_list: this.shops_valais},
    {name:"Fribourg", shop_list: this.shops_fribourg},
    {name:"Vaud", shop_list: this.shops_vaud},
  ]

  dialog_menu: HTMLElement | null = null;

  constructor(private fb: FormBuilder,
              private _snackBar: MatSnackBar,
              public dialog: MatDialog) {}

  closeMenu() {
    this.dialog_menu = document.getElementById('dialog');
    // Check if the div is found
    if (this.dialog_menu) {
      // Do something with the selected div
      this.dialog_menu.classList.add('close');
    } else {
      console.error('Div not found');
    }
  }

  openMenu() {
    this.dialog_menu = document.getElementById('dialog');
    // Check if the div is found
    if (this.dialog_menu) {
      // Do something with the selected div
      this.dialog_menu.classList.remove('close');
    } else {
      console.error('Div not found');
    }
  }

  openPopup() {
    if (this.login_form.invalid) {
      this._snackBar.open('Inserez une adresse email');
      return
    }
    const dialogRef = this.dialog.open(PopupComponent, {
      data: {email: this.login_form.value.email},
    });

    dialogRef.afterClosed().subscribe(result => {});
  }


  onClick(event :MouseEvent){
    event.preventDefault();
  }

  toggleCity(city: any) {
    city.expanded = !city.expanded
  }



}
