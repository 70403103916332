<div *ngIf="cart.articles.length == 0" class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
  <div class="text-center">
    <p class="text-base font-semibold text-stone-600">oups</p>
    <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Votre panier est vide</h1>
    <p class="mt-6 text-base leading-7 text-gray-600">Désolé, nous n'avons pas trouvé d'article dans votre panier.</p>

    <div class="mt-10 flex items-center justify-center gap-x-6">
      <a (click)="nav.goToShop('shop')" class="rounded-md bg-stone-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-stone-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600">Boutique</a>
      <a (click)="nav.goToHome()" class="text-sm font-semibold text-gray-900">Page d'accueil <span aria-hidden="true">&rarr;</span></a>
    </div>
  </div>
</div>


<div *ngIf="cart.articles.length > 0" class="bg-white pt-32">
  <!-- Background color split screen for large screens -->
  <div class="fixed left-0 top-0 hidden h-full w-1/2 bg-white lg:block" aria-hidden="true"></div>
  <div class="fixed right-0 top-0 hidden h-full w-1/2 bg-stone-50 lg:block" aria-hidden="true"></div>

  <div class="relative mx-auto grid max-w-7xl grid-cols-1 gap-x-16 lg:grid-cols-2 lg:px-8 xl:gap-x-48">
    <h1 class="sr-only">Informations sur la commandes</h1>

    <section aria-labelledby="summary-heading" class="bg-stone-50 px-4 pb-10 pt-16 sm:px-6 lg:col-start-2 lg:row-start-1 lg:bg-transparent lg:px-0 lg:pb-16">
      <div class="mx-auto max-w-lg lg:max-w-none">
        <h2 id="summary-heading" class="h2">Résumé</h2>

        <ul role="list" class="divide-y divide-gray-200 text-sm font-medium text-gray-900">
          <li *ngFor="let article of cart.articles" class="flex items-start space-x-4 py-6">
            <img src="/assets/img/article/{{article.id! % 4}}.jpg" alt="Moss green canvas compact backpack with double top zipper, zipper front pouch, and matching carry handle and backpack straps." class="h-20 w-20 flex-none rounded-md object-cover object-center">
            <div class="flex-auto space-y-1">
              <h3>{{article.name}}</h3>
              <p class="text-gray-500">{{article.brand}}</p>
              <p class="text-gray-500">{{article.color}} / {{article.size}}</p>
            </div>
            <p class="flex-none text-base font-medium">{{article.price}} CHF</p>
          </li>
          <!-- More products -->
        </ul>

        <dl class="hidden space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-900 lg:block">
          <div class="flex items-center justify-between">
            <dt class="text-gray-600">Sous-total</dt>
            <dd>{{cart.total! - (cart.total! * 0.08)}} CHF</dd>
          </div>

<!--          <div class="flex items-center justify-between">-->
<!--            <dt class="text-gray-600">Livraison</dt>-->
<!--            <dd>{{checkoutForm.get('deliveryMethod')?.value.price}} CHF</dd>-->
<!--          </div>-->

          <div class="flex items-center justify-between">
            <dt class="text-gray-600">TVA</dt>
            <dd>{{cart.total! * 0.08}} CHF</dd>
          </div>

          <div class="flex items-center justify-between border-t border-gray-200 pt-6">
            <dt class="text-base">Total</dt>
            <dd class="text-base">{{cart.total}} CHF</dd>
          </div>
        </dl>

        <div class="fixed inset-x-0 bottom-0 flex flex-col-reverse text-sm font-medium text-gray-900 lg:hidden z-50">
          <div class="relative z-10 border-t border-gray-200 bg-white px-4 sm:px-6">
            <div class="mx-auto max-w-lg">
              <button type="button" class="flex w-full items-center py-6 font-medium" aria-expanded="false">
                <span class="mr-auto text-base">Total</span>
                <span class="mr-2 text-base">$361.80</span>
                <svg (click)="toggleCheckout()" class="h-5 w-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>
          </div>

          <div>
            <!--
              Mobile summary overlay, show/hide based on mobile summary state.

              Entering: "transition-opacity ease-linear duration-300"
                From: "opacity-0"
                To: "opacity-100"
              Leaving: "transition-opacity ease-linear duration-300"
                From: "opacity-100"
                To: "opacity-0"
            -->
            <div @opacity *ngIf='checkoutOpen' class="fixed inset-0 bg-black bg-opacity-25" aria-hidden="true"></div>

            <!--
              Mobile summary, show/hide based on mobile summary state.

              Entering: "transition ease-in-out duration-300 transform"
                From: "translate-y-full"
                To: "translate-y-0"
              Leaving: "transition ease-in-out duration-300 transform"
                From: "translate-y-0"
                To: "translate-y-full"
            -->
            <div @translateY *ngIf='checkoutOpen' class="relative bg-white px-4 py-6 sm:px-6">
              <dl class="mx-auto max-w-lg space-y-6">
                <div class="flex items-center justify-between">
                  <dt class="text-gray-600">Subtotal</dt>
                  <dd>$320.00</dd>
                </div>

                <div class="flex items-center justify-between">
                  <dt class="text-gray-600">Shipping</dt>
                  <dd>$15.00</dd>
                </div>

                <div class="flex items-center justify-between">
                  <dt class="text-gray-600">Taxes</dt>
                  <dd>$26.80</dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </section>

    <form [formGroup]="checkoutForm" class="mx-auto max-w-lg lg:max-w-none">
      <div class="pt-16">
        <div>
          <h2 class="h2">Informations de contact</h2>

          <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
            <div>
              <label for="first-name" class="label">Prénom</label>
              <div class="mt-1">
                <input formControlName="firstname"
                       type="text" id="first-name" name="firstname" autocomplete="given-name"
                       class="input">
              </div>
            </div>

            <div>
              <label for="last-name" class="label">Nom de famille</label>
              <div class="mt-1">
                <input formControlName="lastname"
                       type="text" id="last-name" name="lastname" autocomplete="family-name"
                       class="input">
              </div>
            </div>
            <div class="sm:col-span-2">
              <label for="email-address" class="label">Adresse email</label>
              <div class="mt-1">
                <input formControlName="email" type="email" id="email-address" name="email-address" autocomplete="email"
                       class="input">
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="phone" class="label">Téléphone</label>
              <div class="mt-1">
                <input formControlName="phone" type="text" name="phone" id="phone" autocomplete="tel"
                       class="input">
              </div>
            </div>
          </div>

<!--          <div class="mt-10 border-t border-gray-200 pt-10">-->
            <!--          <h2 class="h2">Informations sur l'expédition</h2>-->



            <!--            <div class="sm:col-span-2">-->
            <!--              <label for="company" class="label">Company</label>-->
            <!--              <div class="mt-1">-->
            <!--                <input type="text" name="company" id="company"-->
            <!--                       class="input">-->
            <!--              </div>-->
            <!--            </div>-->

            <!--            <div class="sm:col-span-2">-->
            <!--              <label for="address" class="label">Address</label>-->
            <!--              <div class="mt-1">-->
            <!--                <input type="text" name="address" id="address" autocomplete="street-address"-->
            <!--                       class="input">-->
            <!--              </div>-->
            <!--            </div>-->

            <!--            <div class="sm:col-span-2">-->
            <!--              <label for="apartment" class="label">Apartment, suite, etc.</label>-->
            <!--              <div class="mt-1">-->
            <!--                <input type="text" name="apartment" id="apartment"-->
            <!--                       class="input">-->
            <!--              </div>-->
            <!--            </div>-->

            <!--            <div>-->
            <!--              <label for="city" class="label">City</label>-->
            <!--              <div class="mt-1">-->
            <!--                <input type="text" name="city" id="city" autocomplete="address-level2"-->
            <!--                       class="input">-->
            <!--              </div>-->
            <!--            </div>-->

            <!--            <div>-->
            <!--              <label for="country" class="label">Country</label>-->
            <!--              <div class="mt-1">-->
            <!--                <select id="country" name="country" autocomplete="country-name"-->
            <!--                        class="input">-->
            <!--                  <option>United States</option>-->
            <!--                  <option>Canada</option>-->
            <!--                  <option>Mexico</option>-->
            <!--                </select>-->
            <!--              </div>-->
            <!--            </div>-->

            <!--            <div>-->
            <!--              <label for="region" class="label">State / Province</label>-->
            <!--              <div class="mt-1">-->
            <!--                <input type="text" name="region" id="region" autocomplete="address-level1"-->
            <!--                       class="input">-->
            <!--              </div>-->
            <!--            </div>-->

            <!--            <div>-->
            <!--              <label for="postal-code" class="label">Postal code</label>-->
            <!--              <div class="mt-1">-->
            <!--                <input type="text" name="postal-code" id="postal-code" autocomplete="postal-code"-->
            <!--                       class="input">-->
            <!--              </div>-->
            <!--            </div>-->


<!--          </div>-->
        </div>

        <div class="mt-10 border-t border-gray-200 pt-10">
          <fieldset>
            <legend class="h2">Méthode de livraison</legend>

            <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
              <!--
                Checked: "border-transparent", Not Checked: "border-gray-300"
                Active: "ring-2 ring-stone-500"
              -->
              <label *ngFor="let method of deliveryMethods; let i=index"
                     [ngClass]="[checkoutForm.get('deliveryMethod')?.value == method.value ? 'border-transparent ring-2 ring-stone-500': 'border-gray-300']"
                     class="relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none">
                <input formControlName="deliveryMethod" [disabled]="method.disabled"
                       type="radio" name="deliveryMethod" value="{{method.value}}" class="sr-only"
                       aria-labelledby="delivery-method-0-label" aria-describedby="delivery-method-0-description-0 delivery-method-0-description-1">
                <span class="flex flex-1">
                  <span class="flex flex-col">
                    <span id="delivery-method-{{i}}-label" class="block text-sm font-medium text-gray-900">{{method.name}}</span>
                    <span id="delivery-method-{{i}}-description-0" class="mt-1 flex items-center text-sm text-gray-500">{{method.description}}</span>
                    <span id="delivery-method-{{i}}-description-1" class="mt-6 text-sm font-medium text-gray-900">{{method.price}}.- CHF</span>
                  </span>
                </span>
                <!-- Not Checked: "hidden" -->
                <svg [ngClass]="[checkoutForm.get('deliveryMethod')?.value == method.value ? '': 'hidden']"
                     class="h-5 w-5 text-stone-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                </svg>
                <!--
                  Active: "border", Not Active: "border-2"
                  Checked: "border-stone-500", Not Checked: "border-transparent"
                -->
                <span [ngClass]="[checkoutForm.get('deliveryMethod')?.value == method.value ? 'border-transparent border-2': 'border border-gray-300']"
                      class="pointer-events-none absolute -inset-px rounded-lg border-2" aria-hidden="true"></span>
              </label>
            </div>
          </fieldset>
        </div>

        <!-- Payment -->
        <div class="mt-10 border-t border-gray-200 pt-10">
          <h2 class="h2">Paiement</h2>

          <fieldset class="mt-4 z-0">
            <legend class="sr-only">Type de paiement</legend>
            <div class="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
<!--              <div class="flex items-center">-->
<!--                <input id="credit-card" name="payment-type" type="radio" checked class="h-4 w-4 border-gray-300 text-stone-600 focus:ring-stone-500">-->
<!--                <label for="credit-card" class="ml-3 label">Online</label>-->
<!--              </div>-->
              <div class="flex items-center">
                <input id="paypal" name="payment-type" type="radio" class="h-4 w-4 border-gray-300 text-stone-600 focus:ring-stone-500">
                <label for="paypal" class="ml-3 label">Sur place, en magasin</label>
              </div>
            </div>
          </fieldset>

          <!--          <div class="mt-6 grid grid-cols-4 gap-x-4 gap-y-6">-->
          <!--            <div class="col-span-4">-->
          <!--              <label for="card-number" class="label">Card number</label>-->
          <!--              <div class="mt-1">-->
          <!--                <input type="text" id="card-number" name="card-number" autocomplete="cc-number" class="input">-->
          <!--              </div>-->
          <!--            </div>-->

          <!--            <div class="col-span-4">-->
          <!--              <label for="name-on-card" class="label">Name on card</label>-->
          <!--              <div class="mt-1">-->
          <!--                <input type="text" id="name-on-card" name="name-on-card" autocomplete="cc-name" class="input">-->
          <!--              </div>-->
          <!--            </div>-->

          <!--            <div class="col-span-3">-->
          <!--              <label for="expiration-date" class="label">Expiration date (MM/YY)</label>-->
          <!--              <div class="mt-1">-->
          <!--                <input type="text" name="expiration-date" id="expiration-date" autocomplete="cc-exp" class="input">-->
          <!--              </div>-->
          <!--            </div>-->

          <!--            <div>-->
          <!--              <label for="cvc" class="label">CVC</label>-->
          <!--              <div class="mt-1">-->
          <!--                <input type="text" name="cvc" id="cvc" autocomplete="csc" class="input">-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
      <div class="mt-10 border-t border-gray-200 pt-6 sm:flex sm:items-center sm:justify-between">
        <button (click)="checkout()" type="submit" class="w-full rounded-md border border-transparent bg-stone-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:order-last sm:ml-6 sm:w-auto">Réserver la commande chez le commerçant</button>
        <p class="mt-4 text-center text-sm text-gray-500 sm:mt-0 sm:text-left">Vous ne serez facturé qu'à l'étape suivante.</p>
      </div>
    </form>
  </div>
</div>
