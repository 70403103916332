<span class="ml-6 flex items-center">
<!-- Expand icon, show/hide based on section open state.-->
  <svg *ngIf="!isOpen" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
    <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
  </svg>
<!-- Collapse icon, show/hide based on section open state. -->
  <svg *ngIf="isOpen" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
    <path fill-rule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clip-rule="evenodd" />
  </svg>


<!--  <svg @rotate viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500 ng-tns-c2145528021-1">-->
<!--    <path fill-rule="evenodd"-->
<!--          d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"-->
<!--          clip-rule="evenodd" class="ng-tns-c2145528021-1">-->
<!--    </path>-->
<!--  </svg>-->
</span>
