import { Component } from '@angular/core';
import {UserService} from "../../../shared/services/user.service";
import {NavigationService} from "../../../shared/services/navigation.service";

@Component({
  selector: 'app-user-delete-account',
  templateUrl: './user-delete-account.component.html',
  styleUrl: './user-delete-account.component.scss'
})
export class UserDeleteAccountComponent {

  constructor(protected userService: UserService,
              protected nav: NavigationService) {
  }


}
