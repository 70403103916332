import {Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BffApiService} from "../../../shared/api/bff-api.service";
import {MatDialogRef} from "@angular/material/dialog";
import {
  errorGeneral,
  SnackBarService,
  successSubscription,
  wrongEmailAdress
} from "../../../shared/services/snack/snack-bar.service";


@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent {

  states = [
    {name: 'Berne', abbrev: 'BE'},
    {name: 'Genève', abbrev: 'JU'},
    {name: 'Jura', abbrev: 'JU'},
    {name: 'Valais', abbrev: 'VS'},
    {name: 'Vaud', abbrev: 'VD'},
  ];

  login_form: FormGroup  = this.fb.group({
    email: [null, [Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")] ],
    firstName: [null, Validators.required,],
    lastName: [null, Validators.required,],
    companyName: [null, Validators.required,],
    state: [null, Validators.required,],
  })
  constructor(private fb: FormBuilder,
              private bff: BffApiService,
              public dialogRef: MatDialogRef<PopupComponent>,
              private snack: SnackBarService) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit() {
    this.bff.register_newsletter(this.login_form.value).subscribe({
      next: (data) => {
        this.snack.snack(successSubscription)
        this.onNoClick()
      },
      error: (e) => {
        if (e.error.message == 'Email is already registred') {
          this.snack.snack(wrongEmailAdress)
        }
        else {
          this.snack.snack(errorGeneral)
        }
      },
      complete: () => console.info('complete')
    })
  }

}
