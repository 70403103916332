
<div class=" pb-14 pt-32 mt-32">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-2xl lg:max-w-none">
      <div class="text-center">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Magasins partenaires
        </h2>
        <p class="mt-4 text-lg leading-8 text-gray-600">
          <b>Nous sommes fiers de travailler avec des partenaires de qualité.</b><br>
          Grâce à leur expertise, nous sommes en mesure de vous proposer des produits de qualité. Cela nous permet de fournir la plateforme la plus attrayante possible pour nos clients, tout en contribuant à un avenir plus responsable.</p>
      </div>
    </div>
  </div>
</div>


<div class="flex lg:px-8">
  <div *ngFor="let canton of cantons" class="mx-auto w-1/3 max-w-7xl px-6 py-24 sm:py-32 lg:px-8 min-w-72">
    <div class="mx-auto max-w-4xl divide-y divide-gray-900/10">
      <h2 class="text-2xl font-bold leading-10 tracking-tight text-gray-900">{{canton.name}}</h2>
      <dl class="mt-10 space-y-6 divide-y divide-gray-900/10">
        <div  *ngFor="let city of canton.shop_list" class="pt-6">
          <dt>
            <!-- Expand/collapse question button -->
            <button type="button" (click)="toggleCity(city)"
                    class="flex w-full items-start justify-between text-left text-gray-900"
                    aria-controls="faq-0" aria-expanded="false">
              <span class="text-base font-semibold leading-7">{{city.city}}</span>
              <span class="ml-6 flex h-7 items-center">
                <!--
                  Icon when question is collapsed.

                  Item expanded: "hidden", Item collapsed: ""
                -->
                <svg class="h-6 w-6" [ngClass]="city.expanded? 'hidden': ''" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
                </svg>
                <!--
                  Icon when question is expanded.

                  Item expanded: "", Item collapsed: "hidden"
                -->
                <svg [ngClass]="city.expanded? '': 'hidden'" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M18 12H6" />
                </svg>
              </span>
            </button>
          </dt>
          <dd class="mt-2 pr-12" id="faq-0" *ngIf="city.expanded">
            <p *ngFor="let shop of city.shops" class="text-base leading-7 text-gray-600">{{shop.name}}</p>
          </dd>
        </div>
      </dl>
    </div>
  </div>



</div>




