<div class="relative overflow-hidden mt-20">
  <div class="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
    <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-8">
      <h1 class="mt-10 text-3xl font-bold tracking-tight text-gray-900 sm:text-6xl">A propos</h1>
      <p class="mt-6 text-lg leading-8 text-gray-600">Vestra est la première plateforme internet en Suisse destinée aux passionnés et aux magasins de sports. Son objectif : proposer une solution à la problématique des articles invendus.
      </p>
      <p class="mt-6 text-base leading-8 text-gray-600">
        En facilitant la connexion entre les magasins de sport et les consommateurs, Vestra permet aux articles invendus de trouver une vie chez le consommateur, tout en générant des revenus pour les magasins partenaires.
      </p>
      <p class="mt-6 text-base leading-8 text-gray-600">
        L’objectif est de mettre en avant les articles invendus, mais également les magasins qui cherchent à les valoriser. Ainsi, chaque consommateur pourra trouver des articles neufs, de qualité et à des prix attractifs.
      </p>
      <div class="mt-10 flex items-center gap-x-6">
        <a (click)="nav.goToShop('/shop/')" class="cursor-pointer rounded-md bg-stone-600 px-3.5 py-2.5 text-sm font-semibold text-white
        shadow-sm hover:bg-stone-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
        focus-visible:outline-stone-600">Shop</a>
        <a (click)="nav.goTo('/support-commerce')" class="cursor-pointer text-sm font-semibold leading-6 text-gray-900">Commerces <span aria-hidden="true">→</span></a>
      </div>
    </div>
    <div class="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
      <div class="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
        <div class="-m-2 rounded-xl p-2 lg:-m-4 lg:rounded-2xl lg:p-4">
          <img style="width: auto; height: 650px" src="assets/img/Snow_Hiver_Couloir_Rocher.webp" alt="App screenshot" width="3024" height="4032" class="w-[76rem] rounded-md shadow-2xl ring-1 ring-gray-900/10">
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Feature section -->
<div class="mx-auto mt-32 max-w-7xl px-6 lg:px-8">
  <div class="mx-auto max-w-2xl lg:text-center">
    <h2 class="text-base font-semibold leading-7 text-stone-600">L'expérience Vestra</h2>
    <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Comment ça marche ?</p>
    <p class="mt-6 text-lg leading-8 text-gray-600">Pour acheter un des articles proposés par les magasins partenaires de Vestra, rien de plus simple</p>
  </div>
  <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
    <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
      <div class="relative pl-16">
        <dt class="text-base font-semibold leading-7 text-gray-900">
          <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-stone-600">
            <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 3.75V16.5L12 14.25 7.5 16.5V3.75m9 0H18A2.25 2.25 0 0 1 20.25 6v12A2.25 2.25 0 0 1 18 20.25H6A2.25 2.25 0 0 1 3.75 18V6A2.25 2.25 0 0 1 6 3.75h1.5m9 0h-9" />
            </svg>
          </div>
          Réservez
        </dt>
        <dd class="mt-2 text-base leading-7 text-gray-600"> votre article de sport préféré sur Vestra</dd>
      </div>
      <div class="relative pl-16">
        <dt class="text-base font-semibold leading-7 text-gray-900">
          <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-stone-600">
            <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
            </svg>
          </div>
          Payez
        </dt>
        <dd class="mt-2 text-base leading-7 text-gray-600">en ligne ou directement en magasin</dd>
      </div>
      <div class="relative pl-16">
        <dt class="text-base font-semibold leading-7 text-gray-900">
          <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-stone-600">
            <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M21 11.25v8.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 1 0 9.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1 1 14.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
            </svg>
          </div>
          Récupérez
        </dt>
        <dd class="mt-2 text-base leading-7 text-gray-600">votre article auprès du magasin revendeur</dd>
      </div>
      <div class="relative pl-16">
        <dt class="text-base font-semibold leading-7 text-gray-900">
          <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-stone-600">
            <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg>
          </div>
          N’hésitez pas !
        </dt>
        <dd class="mt-2 text-base leading-7 text-gray-600">Vous avez 10 jours dès la récupération de l’article pour le retourner en magasin !</dd>
      </div>
    </dl>
  </div>
</div>


<!-- Content section -->
<div class="mt-24 overflow-hidden">
  <div class="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8 mx-auto max-w-2xl">
    <h2 class="mx-auto text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:mx-0 mx-36">Vestra, d'où ça vient ?</h2>
    <!--        <p class="mt-6 text-base leading-7 text-gray-600">Diam nunc lacus lacus aliquam turpis enim. Eget hac velit est euismod lacus. Est non placerat nam arcu. Cras purus nibh cursus sit eu in id. Integer vel nibh.</p>-->
  </div>
  <div class="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
    <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
      <div class="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
        <p class="mt-6 text-base leading-7 text-gray-600">Vestra a vu le jour dans l'esprit d'un étudiant en management passionné par les sports de montagne. En côtoyant le domaine du commerce de matériel de sport en parallèle de ses études, il a identifié une problématique :
          <br>
          Une proportion importante des articles mis en vente par les magasins de sport ne trouvent pas d’acquéreur.
        </p>
        <p class="mt-6 text-base leading-7 text-gray-600">
          En 2023, animé par la volonté d'approfondir cette question, il a décidé d'explorer cette problématique dans le cadre de ses études universitaires. Au fil de ses recherches, il remarque qu'environ 10% des articles achetés par les magasins de sport en Suisse restent invendus après les soldes.
        </p>
      </div>
      <div class="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
        <div class="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
          <img width="800" height="600" src="/assets/img/team/pic.webp" alt="Déstockage habits de sport" class="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover">
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Content section -->

<div class="py-12">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
      <div class="lg:pr-4">
        <div class="relative overflow-hidden rounded-3xl  px-6 pb-9 pt-64 shadow-2xl sm:px-12 lg:max-w-lg lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
          <img class="absolute inset-0 h-full w-full object-cover brightness-125 saturate-0" src="/assets/img/team/pic.webp" alt="">
        </div>
      </div>
      <div>
        <div class="text-base leading-7 text-gray-700 lg:max-w-lg">
          <div class="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
            <p class="mt-6 text-base leading-7 text-gray-600">Durant la suite de ses études, il choisit d'approfondir ses recherches dans le cadre de son travail de Master, pour lequel il décide de se concentrer sur la faisabilité d'une place de marché. Il détaille ainsi les besoins des magasins de sport liés à la problématique des articles invendus. Les conclusions de son travail le poussent à concrétiser une nouvelle place de marché pour leurs offrir une solution concrète.<br>
            </p>
            <p class="mt-6 text-base leading-7 text-gray-600">
              Depuis la fin de l’année 2023, deux jeunes ingénieurs en informatique complètent l’équipe et développent les outils nécessaire au fonctionnement de la plateforme. Vestra est ainsi devenu un projet mené par trois entrepreneurs motivés à construire une solution durable à la problématique des articles invendus !
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="overflow-hidden">
  <div class="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
    <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
      <div class="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
        <div class="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
          <div class="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
            <img width="533" height="800" src="/assets/img/team/grimpe.webp" alt="Braderie en ligne d’articles de sport" class="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover">
          </div>
          <div class="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
            <img width="800" height="600" src="/assets/img/team/sun.webp" alt="E-commerce pour mon magasin de sport" class="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover">
          </div>
          <div class="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
            <img width="600" height="800" src="/assets/img/team/yay.webp" alt="Problèmes avec mes invendus" class="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mx-auto my-32 max-w-7xl px-6 lg:px-8">
  <div class="mx-auto max-w-2xl lg:text-center">
    <h2 class="text-base font-semibold leading-7 text-stone-600">Comment Vestra est né</h2>
    <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Chronologie</p>
  </div>
  <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-xl">
    <nav aria-label="Progress">
      <ol role="list" class="overflow-hidden">
        <li class="relative pb-10">
          <div class="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-stone-600" aria-hidden="true"></div>
          <!-- Complete Step -->
          <span href="#" class="group relative flex items-start">
        <span class="flex h-9 items-center">
          <span class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-stone-600 group-hover:bg-stone-800">
            <svg class="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
          </span>
        </span>
            <span class="ml-4 flex min-w-0 flex-col">
          <span class="text-sm font-medium">Juin 2023</span>
          <span class="text-sm text-gray-500">Etude de la problématique des invendus dans le domaine du sport en Suisse</span>
        </span>
          </span>
        </li>
        <li class="relative pb-10">
          <div class="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true"></div>
          <!-- Current Step -->
          <span href="#" class="group relative flex items-start" aria-current="step">
        <span class="flex h-9 items-center" aria-hidden="true">
          <span class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-stone-600 bg-white">
            <span class="h-2.5 w-2.5 rounded-full bg-stone-600"></span>
          </span>
        </span>
            <span class="ml-4 flex min-w-0 flex-col">
          <span class="text-sm font-medium text-stone-600">Octobre 2023</span>
          <span class="text-sm text-gray-500">Début de la construction de la structure informatique de la plateforme Vestra</span>
        </span>
          </span>
        </li>
        <li class="relative">
          <!-- Upcoming Step -->
          <span href="#" class="group relative flex items-start">
        <span class="flex h-9 items-center" aria-hidden="true">
          <span class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
            <span class="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"></span>
          </span>
        </span>
            <span class="ml-4 flex min-w-0 flex-col">
          <span class="text-sm font-medium text-gray-500">Juillet 2024</span>
          <span class="text-sm text-gray-500">Mise en ligne publique de la plateforme Vestra www.vestrashop.ch</span>
        </span>
          </span>
        </li>
      </ol>
    </nav>
  </div>
</div>
