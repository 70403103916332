import { Component } from '@angular/core';
import {CartService} from "../../../shared/services/cart.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NavigationService} from "../../../shared/services/navigation.service";
import {animate, style, transition, trigger} from "@angular/animations";
import {Basket} from "../../../../../../fe-global-components/interfaces/interfaces";
import {OnSite} from "../../../../../../fe-global-components/constants/deliveryMethods";

@Component({
  selector: 'app-checkout-form',
  templateUrl: './checkout-form.component.html',
  styleUrls: ['./checkout-form.component.scss'],
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease', style({  opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms ease', style({ opacity: 0 }))
      ])
    ]),
    trigger('translateY', [
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('300ms ease-in-out', style({  transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)' }),
        animate('300ms ease-in-out', style({ transform: 'translateY(100%)' }))
      ])
    ])
  ]
})
export class CheckoutFormComponent {
  isMobileSummary = false;
  checkoutOpen = false;
  toggleMobileSummary(){
    this.isMobileSummary = !this.isMobileSummary;
  }
  cart!: Basket;
  deliveryMethods = [OnSite, ]
    // Standard, Express]

  checkoutForm: FormGroup = this.fb.group({
    email: ['', Validators.required],
    firstname: ['', Validators.required],
    lastname: ['', Validators.required],
    phone: ['', Validators.required],
    deliveryMethod: [this.deliveryMethods[0].value, Validators.required],
    payment: ['', ],
  })

  constructor(private cartService: CartService,
              private _snackBar: MatSnackBar,
              private fb: FormBuilder,
              public  nav: NavigationService) {
    this.cartService.currentCart.subscribe(currentCart => this.cart = currentCart);
  }

  checkout() {
    if(this.checkoutForm.invalid){
      this._snackBar.open('Veuillez remplir les champs obligataires.');
      return
    }
    else {
      this.cartService.checkout(this.checkoutForm)
      //TODO save user information
      this.nav.goToSuccessPayment()
    }

  }

  check(xxx: any): void {
    console.log(this.checkoutForm.get('deliveryMethod')?.value);
    console.log(xxx)
  }

  toggleCheckout() {
    this.checkoutOpen = !this.checkoutOpen
  }
}
