<main class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8 my-52 max-w-screen-md mx-auto">
  <div class="text-center">

    <div class="rounded-full w-16 h-16 bg-stone-600 text-white flex justify-center items-center select-none p-2 m-2 mx-auto">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-9 h-9">
        <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
      </svg>
    </div>

    <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Adresse email vérifiée</h1>
    <p class="mt-6 text-base leading-7 text-gray-600">Votre adresse email a été vérifié avec succès !</p>
    <div class="mt-10 flex items-center justify-center gap-x-6">
      <a (click)="this.nav.goToHome()" target="_blank" class="text-sm font-semibold text-gray-900">Page d'accueil <span aria-hidden="true">&rarr;</span></a>
    </div>
  </div>
</main>
