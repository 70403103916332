import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BffApiService} from "../../../shared/api/bff-api.service";
import {NavigationService} from "../../../shared/services/navigation.service";
import {UserService} from "../../../shared/services/user.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-page-register',
  templateUrl: './page-register.component.html',
  styleUrls: ['../connexion.scss']
})
export class PageRegisterComponent implements OnInit {
  register_form: FormGroup = this.fb.group({
    email: [null, Validators.required],
    lastname: [null, Validators.required],
    firstname: [null, Validators.required],
    password: [null, Validators.required],
    repeat_password: [null, Validators.required],
  })

  constructor(private fb: FormBuilder,
              private snack: MatSnackBar,
              private userService: UserService,
              protected nav: NavigationService) {
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.register_form = this.fb.group({
      email: [null, Validators.required],
      lastname: [null, Validators.required],
      firstname: [null, Validators.required],
      password: [null, Validators.required],
      password_confirmation: [null, Validators.required],
    })
  }

  submit() {
    if (this.register_form.invalid) {
      this.snack.open('Le formulaire est faux veuillez verifier les informations entrées')
      return
    }
    this.userService.register(this.register_form.value)
  }
}
