import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UserService } from "../../../../shared/services/user.service";
import { NavigationService } from "../../../../shared/services/navigation.service";
import { BffApiService } from "../../../../shared/api/bff-api.service";

@Component({
  selector: 'app-user-security',
  templateUrl: './user-security.component.html',
  styleUrl: './user-security.component.scss'
})
export class UserSecurityComponent {
  changePassword: FormGroup = this.fb.group({
    now_password: [null, Validators.required],
    password: [null, Validators.required],
    repeat_password: [null, Validators.required],
  })

  isChangePWD: boolean = false;
  user: any = {};

  constructor(private fb: FormBuilder,
    private snack: MatSnackBar,
    private userService: UserService,
    protected nav: NavigationService) {


    this.userService.currentUser.subscribe((user: any) => {
      console.log(user)
      this.user = user
    })

  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.changePassword = this.fb.group({
      old_password: [null, Validators.required],
      password: [null, Validators.required],
      password_confirmation: [null, Validators.required],
    })
  }


  submit() {
    if (this.changePassword.invalid) {
      this.snack.open('Le formulaire est invalide. Veuillez verifier les informations entrées')
      return
    }
    const form = {
      current_password: this.changePassword.value.old_password,
      password: this.changePassword.value.password
    }
    this.userService.changePWD(form)
  }

  togglePWD() {
    this.isChangePWD = !this.isChangePWD
  }

  sendVerificationEmail() {
    this.userService.sendVerificationEmail()
  }


}
