<!--
  This example requires updating your template:
  ```
  <html class="h-full bg-white">
  <body class="h-full">
  ```
-->
<main class="relative lg:min-h-full mt-20">
  <div class="h-80 overflow-hidden lg:absolute lg:h-full lg:w-1/2 lg:pr-4 xl:pr-12">
    <img src="/assets/img/boxed-water-is-better-1NCcWi24FRs-unsplash.jpg" alt="TODO" class="h-full w-full object-cover object-center">
  </div>

  <div>
    <div class="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-32 xl:gap-x-24">
      <div class="lg:col-start-2">
        <h1 class="text-sm font-medium text-stone-600">Commande réservée</h1>
        <p class="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Merci d'avoir commandé</p>
        <p class="mt-2 text-base text-gray-500">Nous apprécions votre commande, nous sommes en train de la traiter.
          Nous vous enverrons une confirmation très bientôt !<br>
          Récupérez vos articles dans le magasin partenaire</p>

        <dl class="mt-16 text-sm font-medium">
          <dt class="text-gray-900">Numéro de suivi</dt>
          <dd class="mt-2 text-stone-600">TODO</dd>
        </dl>

        <ul role="list" class="mt-6 divide-y divide-gray-200 border-t border-gray-200 text-sm font-medium text-gray-500">
          <li *ngFor="let article of articles" class="flex space-x-6 py-6">
            <img src="https://tailwindui.com/img/ecommerce-images/confirmation-page-06-product-01.jpg" alt="Model wearing men&#039;s charcoal basic tee in large." class="h-24 w-24 flex-none rounded-md bg-gray-100 object-cover object-center">
            <div class="flex-auto space-y-1">
              <h3 class="text-gray-900">
                <a href="#">{{article.n}}</a>
              </h3>
              <p>{{article.color}}</p>
              <p>{{article.size}}</p>
            </div>
            <p class="flex-none font-medium text-gray-900">{{article.price}}</p>
          </li>

          <!-- More products... -->
        </ul>

        <dl class="space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-500">
          <div class="flex justify-between">
            <dt>Sous-Total</dt>
            <dd class="text-gray-900">{{cart.total! - (cart.total! *0.08)}}</dd>
          </div>

          <div class="flex justify-between">
            <dt>Livraison</dt>
            <dd class="text-gray-900">TODO</dd>
          </div>

          <div class="flex justify-between">
            <dt>TVA</dt>
            <dd class="text-gray-900">{{cart.total! *0.08}}</dd>
          </div>

          <div class="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">
            <dt class="text-base">Total</dt>
            <dd class="text-base">TODO</dd>
          </div>
        </dl>

        <dl class="mt-16 grid grid-cols-2 gap-x-4 text-sm text-gray-600">
          <div>
            <dt class="font-medium text-gray-900">Shipping Address</dt>
            <dd class="mt-2">
              <address class="not-italic">
                <span class="block">Kristin Watson</span>
                <span class="block">7363 Cynthia Pass</span>
                <span class="block">Toronto, ON N3Y 4H8</span>
              </address>
            </dd>
          </div>
          <div>
            <dt class="font-medium text-gray-900">Payment Information</dt>
            <dd class="mt-2 space-y-2 sm:flex sm:space-x-4 sm:space-y-0">
              <div class="flex-none">
                <svg aria-hidden="true" width="36" height="24" viewBox="0 0 36 24" class="h-6 w-auto">
                  <rect width="36" height="24" rx="4" fill="#224DBA" />
                  <path d="M10.925 15.673H8.874l-1.538-6c-.073-.276-.228-.52-.456-.635A6.575 6.575 0 005 8.403v-.231h3.304c.456 0 .798.347.855.75l.798 4.328 2.05-5.078h1.994l-3.076 7.5zm4.216 0h-1.937L14.8 8.172h1.937l-1.595 7.5zm4.101-5.422c.057-.404.399-.635.798-.635a3.54 3.54 0 011.88.346l.342-1.615A4.808 4.808 0 0020.496 8c-1.88 0-3.248 1.039-3.248 2.481 0 1.097.969 1.673 1.653 2.02.74.346 1.025.577.968.923 0 .519-.57.75-1.139.75a4.795 4.795 0 01-1.994-.462l-.342 1.616a5.48 5.48 0 002.108.404c2.108.057 3.418-.981 3.418-2.539 0-1.962-2.678-2.077-2.678-2.942zm9.457 5.422L27.16 8.172h-1.652a.858.858 0 00-.798.577l-2.848 6.924h1.994l.398-1.096h2.45l.228 1.096h1.766zm-2.905-5.482l.57 2.827h-1.596l1.026-2.827z" fill="#fff" />
                </svg>
                <p class="sr-only">Visa</p>
              </div>
              <div class="flex-auto">
                <p class="text-gray-900">Ending with 4242</p>
                <p>Expires 12 / 21</p>
              </div>
            </dd>
          </div>
        </dl>

        <div class="mt-16 border-t border-gray-200 py-6 text-right">
          <a href="#" class="text-sm font-medium text-stone-600 hover:text-stone-500">
            Poursuivre les achats
            <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</main>
