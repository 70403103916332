import { Component } from '@angular/core';
import {NavigationService} from "../../../shared/services/navigation.service";

@Component({
  selector: 'app-page-logout',
  templateUrl: './page-logout.component.html',
  styleUrls: ['../connexion.scss']
})
export class PageLogoutComponent {
  constructor(protected nav: NavigationService) {
  }
}
