<div class="py-24">
  <div class="pb-16 pt-6 sm:pb-24">
    <div class="mx-auto mt-8 max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <div class="lg:grid lg:auto-rows-min lg:grid-cols-12 lg:gap-x-8">
        <div class="lg:col-span-5 lg:col-start-8">
          <div class="flex justify-between">
            <h1 class="text-xl font-medium text-gray-900">{{product.name}}</h1>
            <p class="text-xl font-medium text-gray-900"><span class="line-through">{{product.old_price}} CHF</span>&nbsp;<span>{{product.price}}CHF</span></p>
          </div>
          <!-- Reviews -->
<!--          <div class="mt-4">-->
<!--            <h2 class="sr-only">Reviews</h2>-->
<!--            <div class="flex items-center">-->
<!--              <p class="text-sm text-gray-700">-->
<!--                3.9-->
<!--                <span class="sr-only"> out of 5 stars</span>-->
<!--              </p>-->
<!--              <div class="ml-1 flex items-center">-->
<!--                &lt;!&ndash; Active: "text-yellow-400", Inactive: "text-gray-200" &ndash;&gt;-->
<!--                <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">-->
<!--                  <path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd" />-->
<!--                </svg>-->
<!--                <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">-->
<!--                  <path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd" />-->
<!--                </svg>-->
<!--                <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">-->
<!--                  <path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd" />-->
<!--                </svg>-->
<!--                <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">-->
<!--                  <path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd" />-->
<!--                </svg>-->
<!--                <svg class="text-gray-200 h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">-->
<!--                  <path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd" />-->
<!--                </svg>-->
<!--              </div>-->
<!--              <div aria-hidden="true" class="ml-4 text-sm text-gray-300">·</div>-->
<!--              <div class="ml-4 flex">-->
<!--                <a href="#" class="text-sm font-medium text-stone-600 hover:text-stone-500">See all 512 reviews</a>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </div>

        <!-- Image gallery -->
        <div class="mt-8 lg:col-span-7 lg:col-start-1 lg:row-span-3 lg:row-start-1 lg:mt-0">
          <h2 class="sr-only">Images</h2>

          <div class="grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-3 lg:gap-8">
            <app-img-carousel></app-img-carousel>
          </div>
        </div>

        <div class="mt-8 lg:col-span-5">
          <form [formGroup]="article_form">
            <!-- Color picker -->
            <div>
              <h4 class="text-sm font-medium text-gray-900">Couleurs</h4>

              <fieldset class="mt-2">
                <legend class="sr-only">Choisir une couleur</legend>
                <div class="flex items-center space-x-3">
                  <!--
                    Active and Checked: "ring ring-offset-1"
                    Not Active and Checked: "ring-2"
                  -->
                  <label *ngFor="let color of colors; let i=index"
                         [ngClass]="{
                            'ring': currentColor.id === color.id,
                            'ring-offset-1': currentColor.id === color.id,
                            'ring-2': currentColor.id === color.id,
                          }"
                         [class]="color.ringColor" class="relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none">
                    <input formControlName="color" type="radio"
                           (click)="selectColor(color)" name="color" value="{{color.name }}"
                           class="sr-only" attr.aria-labelledby="color-choice-{{i}}-label">
                    <span id="color-choice-{{i}}-label" class="sr-only">{{ color.name }}</span>
                    <span aria-hidden="true" [style.background-color]="color.backgroundColor" class="h-8 w-8 rounded-full border border-black border-opacity-10"></span>
                  </label>
                </div>
              </fieldset>
            </div>

            <!-- Size picker -->
            <div class="mt-8">
              <div class="flex items-center justify-between">
                <h4 class="text-sm font-medium text-gray-900">Taile</h4>
                <a href="/assets/img/sizeguide-clothes-en.jpg" target="_blank" class="text-sm font-medium text-stone-600 hover:text-stone-500">Guide des tailles</a>
              </div>

              <fieldset class="mt-2">
                <legend class="sr-only">Choisir une taille</legend>
                <div class="grid grid-cols-7 gap-2">
                  <!--
                    In Stock: "cursor-pointer", Out of Stock: "opacity-25 cursor-not-allowed"
                    Active: "ring-2 ring-stone-500 ring-offset-2"
                    Checked: "border-transparent bg-stone-600 text-white hover:bg-stone-700", Not Checked: "border-gray-200 bg-white text-gray-900 hover:bg-gray-50"
                  -->
                  <label *ngFor="let size of sizes; let i=index"
                         [ngClass]="{
                            'cursor-pointer': size.available,
                            'cursor-not-allowed': ! size.available,
                            'opacity-25': ! size.available,
                            'ring-2': currentSize.id ===  size.id,
                            'ring-offset-2': currentSize.id ===  size.id,
                            'ring-stone-500': currentSize.id ===  size.id,
                            'bg-stone-600': currentSize.id ===  size.id,
                            'border-transparent': currentSize.id ===  size.id,
                            'text-white': currentSize.id === size.id,
                            'hover:bg-stone-700': currentSize.id ===  size.id,
                            'bg-white': currentSize.id !==  size.id,
                            'border-gray-200': currentSize.id !==  size.id,
                            'text-gray-900': currentSize.id !==  size.id,
                            'hover:bg-gray-50': currentSize.id !==  size.id
                          }"
                         (click)="selectSize(size)"
                         class="border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1 cursor-pointer focus:outline-none">
                    <input formControlName="size" type="radio"
                           name="size" value="{{ size.name }}" class="sr-only" attr.aria-labelledby="size-choice-{{i}}-label">
                    <span id="size-choice-{{i}}-label">{{ size.name }}</span>
                  </label>
                </div>
              </fieldset>
            </div>

            <button type="submit" (click)="cartService.addArticle(this.article_form, product)"
                    class="mt-8 flex w-full items-center justify-center rounded-md border border-transparent bg-stone-600 px-8 py-3 text-base font-medium text-white hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2">Ajouter au panier</button>
          </form>

          <!-- Product details -->
          <div class="mt-10">
            <h2 class="text-sm font-medium text-gray-900">Description</h2>

            <div class="prose prose-sm mt-4 text-gray-500">
              <p>{{product.description}}</p>
            </div>
          </div>

          <div class="mt-8 border-t border-gray-200 pt-8">
            <h2 class="text-sm font-medium text-gray-900">Fabric & Care</h2>

            <div class="prose prose-sm mt-4 text-gray-500">
              <ul role="list">
                <li>Only the best materials</li>
                <li>Ethically and locally made</li>
                <li>Pre-washed and pre-shrunk</li>
                <li>Machine wash cold with similar colors</li>
              </ul>
            </div>
          </div>

          <!-- Policies -->
          <section aria-labelledby="policies-heading" class="mt-10">
            <h2 id="policies-heading" class="sr-only">Our Policies</h2>

            <dl class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2">
              <div class="rounded-lg border border-gray-200 bg-stone-50 p-6 text-center">
                <dt>
                  <svg class="mx-auto h-6 w-6 flex-shrink-0 text-gray-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.115 5.19l.319 1.913A6 6 0 008.11 10.36L9.75 12l-.387.775c-.217.433-.132.956.21 1.298l1.348 1.348c.21.21.329.497.329.795v1.089c0 .426.24.815.622 1.006l.153.076c.433.217.956.132 1.298-.21l.723-.723a8.7 8.7 0 002.288-4.042 1.087 1.087 0 00-.358-1.099l-1.33-1.108c-.251-.21-.582-.299-.905-.245l-1.17.195a1.125 1.125 0 01-.98-.314l-.295-.295a1.125 1.125 0 010-1.591l.13-.132a1.125 1.125 0 011.3-.21l.603.302a.809.809 0 001.086-1.086L14.25 7.5l1.256-.837a4.5 4.5 0 001.528-1.732l.146-.292M6.115 5.19A9 9 0 1017.18 4.64M6.115 5.19A8.965 8.965 0 0112 3c1.929 0 3.716.607 5.18 1.64" />
                  </svg>
                  <span class="mt-4 text-sm font-medium text-gray-900">International delivery</span>
                </dt>
                <dd class="mt-1 text-sm text-gray-500">Get your order in 2 years</dd>
              </div>
              <div class="rounded-lg border border-gray-200 bg-stone-50 p-6 text-center">
                <dt>
                  <svg class="mx-auto h-6 w-6 flex-shrink-0 text-gray-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <span class="mt-4 text-sm font-medium text-gray-900">Loyalty rewards</span>
                </dt>
                <dd class="mt-1 text-sm text-gray-500">Don&#039;t look at other tees</dd>
              </div>
            </dl>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>
