<!--  promotional message -->
<!-- to keep for now
<p class="flex h-10 items-center justify-center bg-stone-600 px-4
text-sm font-medium text-white sm:px-6 lg:px-8">Frais de livraison offert dès 100.- CHF d'achat</p>
-->
<div>
  <div>
    <!--
      Mobile filter dialog

      Off-canvas filters for mobile, show/hide based on off-canvas filters state.
    -->
    <div *ngIf='isOffCanvasFiltersMenuDialog' class="relative z-40 lg:hidden" role="dialog" aria-modal="true">
      <!--
        Off-canvas menu backdrop, show/hide based on off-canvas menu state.
      -->
      <div @opacityLong *ngIf='isOffCanvasFiltersMenu' (click)="toggleOffCanvasFiltersMenu()" class="fixed inset-0 bg-black bg-opacity-25"></div>

      <div @translateX *ngIf='isOffCanvasFiltersMenu' class="fixed inset-0 z-40 flex">
        <div *ngIf='isOffCanvasFiltersMenu' (click)="toggleOffCanvasFiltersMenu()" class="fixed inset-0"></div>
        <!--
          Off-canvas menu, show/hide based on off-canvas menu state.
        -->
        <div @translateX *ngIf='isOffCanvasFiltersMenu' class="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl">
          <div class="flex items-center justify-between px-4">
            <h2 class="h2">Filters</h2>
            <button type="button" (click)="toggleOffCanvasFiltersMenu()" class="-mr-2 flex h-10 w-10 items-center justify-center p-2 text-gray-400 hover:text-gray-500">
              <span class="sr-only">Close menu</span>
              <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <!-- Filters -->
          <form class="mt-4">
            <div class="border-t border-gray-200 pt-4 pb-4">
              <fieldset>
                <legend class="w-full px-2">
                  <!-- Expand/collapse section button -->
                  <button type="button" (click)="toggleColorMenu()" class="flex w-full items-center justify-between p-2 text-gray-400 hover:text-gray-500" aria-controls="filter-section-0" aria-expanded="false">
                    <span class="text-sm font-medium text-gray-900">Color</span>
                    <span class="ml-6 flex h-7 items-center">
                      <!--
                        Expand/collapse icon, toggle classes based on section open state.

                        Open: "-rotate-180", Closed: "rotate-0"
                      -->
                      <svg [ngClass]="{'-rotate-180': menuStates.isColorMenu, 'rotate-0': !menuStates.isColorMenu }" class="rotate-0 h-5 w-5 transform" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                      </svg>
                    </span>
                  </button>
                </legend>
                <div *ngIf='menuStates.isColorMenu' class="px-4 pt-4 pb-2" id="filter-section-0">
                  <div class="space-y-6">
                    <div *ngFor="let color of colors" class="flex items-center">
                      <input id="color-{{color.id}}-mobile" name="color[]" value="{{color.value}}" type="checkbox" class="checkbox">
                      <label for="color-{{color.id}}-mobile" class="ml-3 text-sm text-gray-500">{{color.name}}</label>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>

            <div class="border-t border-gray-200 pt-4 pb-4">
              <fieldset>
                <legend class="w-full px-2">
                  <!-- Expand/collapse section button -->
                  <button type="button" (click)="toggleCategoryMenu()" class="flex w-full items-center justify-between p-2 text-gray-400 hover:text-gray-500" aria-controls="filter-section-1" aria-expanded="false">
                    <span class="text-sm font-medium text-gray-900">Category</span>
                    <span class="ml-6 flex h-7 items-center">
                      <!--
                        Expand/collapse icon, toggle classes based on section open state.

                        Open: "-rotate-180", Closed: "rotate-0"
                      -->
                      <svg [ngClass]="{'-rotate-180': menuStates.isCategoryMenu, 'rotate-0': !menuStates.isCategoryMenu }" class="rotate-0 h-5 w-5 transform" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                      </svg>
                    </span>
                  </button>
                </legend>
                <div *ngIf='menuStates.isCategoryMenu' class="px-4 pt-4 pb-2" id="filter-section-1">
                  <div class="space-y-6">
                    <div class="flex items-center">
                      <input id="category-0-mobile" name="category[]" value="new-arrivals" type="checkbox" class="checkbox">
                      <label for="category-0-mobile" class="ml-3 text-sm text-gray-500">All New Arrivals</label>
                    </div>

                    <div class="flex items-center">
                      <input id="category-1-mobile" name="category[]" value="tees" type="checkbox" class="checkbox">
                      <label for="category-1-mobile" class="ml-3 text-sm text-gray-500">Tees</label>
                    </div>

                    <div class="flex items-center">
                      <input id="category-2-mobile" name="category[]" value="crewnecks" type="checkbox" class="checkbox">
                      <label for="category-2-mobile" class="ml-3 text-sm text-gray-500">Crewnecks</label>
                    </div>

                    <div class="flex items-center">
                      <input id="category-3-mobile" name="category[]" value="sweatshirts" type="checkbox" class="checkbox">
                      <label for="category-3-mobile" class="ml-3 text-sm text-gray-500">Sweatshirts</label>
                    </div>

                    <div class="flex items-center">
                      <input id="category-4-mobile" name="category[]" value="pants-shorts" type="checkbox" class="checkbox">
                      <label for="category-4-mobile" class="ml-3 text-sm text-gray-500">Pants & Shorts</label>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>

            <div class="border-t border-gray-200 pt-4 pb-4">
              <fieldset>
                <legend class="w-full px-2">
                  <!-- Expand/collapse section button -->
                  <button type="button" (click)="toggleSizeMenu()" class="flex w-full items-center justify-between p-2 text-gray-400 hover:text-gray-500" aria-controls="filter-section-2" aria-expanded="false">
                    <span class="text-sm font-medium text-gray-900">Sizes</span>
                    <span class="ml-6 flex h-7 items-center">
                      <!--
                        Expand/collapse icon, toggle classes based on section open state.

                        Open: "-rotate-180", Closed: "rotate-0"
                      -->
                      <svg [ngClass]="{'-rotate-180': menuStates.isSizeMenu, 'rotate-0': !menuStates.isSizeMenu }" class="rotate-0 h-5 w-5 transform" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                      </svg>
                    </span>
                  </button>
                </legend>
                <div *ngIf='menuStates.isSizeMenu' class="px-4 pt-4 pb-2" id="filter-section-2">
                  <div class="space-y-6">
                    <div *ngFor="let size of sizes" class="flex items-center">
                      <input id="sizes-{{size.id}}-mobile" name="sizes[]" value="{{size.name}}" type="checkbox" class="checkbox">
                      <label for="sizes-{{size.id}}-mobile" class="ml-3 text-sm text-gray-500">{{size.name}}</label>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </form>
        </div>
      </div>
    </div>

    <main class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-24">
       <h1 class="text-4xl font-bold tracking-tight text-gray-900 capitalize">{{pageName}}</h1>
        <div class="flex items-center">
          <button (click)="toggleOffCanvasFiltersMenu()" type="button" class="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden mr-1">
            <span class="sr-only">Filters</span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="w-5 h-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
            </svg>
          </button>

          <div class="relative inline-block text-left">
<!--            <div>--><!--TODO [NEW FEATURE]: jdpjew -->
<!--              <button (click)="toggle_sort()" type="button" class="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900" id="menu-button" aria-expanded="false" aria-haspopup="true">-->
<!--                Trier-->
<!--                <svg class="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">-->
<!--                  <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />-->
<!--                </svg>-->
<!--              </button>-->
<!--            </div>-->

            <!--
              Dropdown menu, show/hide based on menu state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div *ngIf="this.display_sort"
              class="absolute right-0 bg-white z-10 mt-2 w-40 origin-top-right rounded-md  shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
              <div class="py-1" role="none">
                <!--
                  Active: "bg-stone-100", Not Active: ""

                  Selected: "font-medium text-gray-900", Not Selected: "text-gray-500"
                -->
                <a *ngFor="let sort of sorts_articles" (click)="getSort(sort)"
                   class="block px-4 py-2 text-sm cursor-pointer"
                   role="menuitem"
                   [ngClass]="sort.active ? 'bg-stone-100 font-medium text-gray-900' : 'text-gray-500'"
                   tabindex="{{sort.tabindex}}" id="{{sort.id}}">
                  {{sort.name}}</a>
              </div>
            </div>
          </div>

<!--          <button type="button" class="-m-2 ml-5 p-2 text-gray-400 hover:text-gray-500 sm:ml-7">-->
<!--            <span class="sr-only">View grid</span>-->
<!--            <svg class="h-5 w-5" aria-hidden="true" viewBox="0 0 20 20" fill="currentColor">-->
<!--              <path fill-rule="evenodd" d="M4.25 2A2.25 2.25 0 002 4.25v2.5A2.25 2.25 0 004.25 9h2.5A2.25 2.25 0 009 6.75v-2.5A2.25 2.25 0 006.75 2h-2.5zm0 9A2.25 2.25 0 002 13.25v2.5A2.25 2.25 0 004.25 18h2.5A2.25 2.25 0 009 15.75v-2.5A2.25 2.25 0 006.75 11h-2.5zm9-9A2.25 2.25 0 0011 4.25v2.5A2.25 2.25 0 0013.25 9h2.5A2.25 2.25 0 0018 6.75v-2.5A2.25 2.25 0 0015.75 2h-2.5zm0 9A2.25 2.25 0 0011 13.25v2.5A2.25 2.25 0 0013.25 18h2.5A2.25 2.25 0 0018 15.75v-2.5A2.25 2.25 0 0015.75 11h-2.5z" clip-rule="evenodd" />-->
<!--            </svg>-->
<!--          </button>-->

        </div>
      </div>
      <section aria-labelledby="products-heading" class="pb-24 pt-6">
        <h2 id="products-heading" class="sr-only">Products</h2>

        <div class="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
          <!-- Filters -->
          <app-filter-search-article  class="hidden lg:block" ></app-filter-search-article>

          <!-- Product grid -->
          <div class="lg:col-span-3">
            <section class="mx-auto max-w-7xl px-6 lg:px-8 z-0">
            <div class="mx-auto grid max-w-lg grid-cols-1 items-center gap-x-8 gap-y-12
  sm:max-w-none sm:grid-cols-4 sm:gap-x-4 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-4">
              <app-preview-article
                                   (click)="nav.goToArticle(product.id)"
                                   *ngFor="let product of products; let i=index"
                                   [index]="i+1" [product]="product"></app-preview-article> <!--- class="col-span-2 w-full lg:col-span-1" -->
            </div>
          </section>

          </div>
        </div>
      </section>
    </main>
  </div>
</div>

