<div>
  <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
  <div *ngIf='isOffCanvasMenuDialog' class="relative z-50 lg:hidden" role="dialog" aria-modal="true">
    <!--
      Off-canvas menu backdrop, show/hide based on off-canvas menu state.
    -->
    <div @opacity *ngIf='isOffCanvasMenu' class="fixed inset-0 bg-gray-900/80"></div>

    <div @translateX *ngIf='isOffCanvasMenu'  class="fixed inset-0 flex">
      <div *ngIf='isOffCanvasMenu' (click)="toggleOffCanvasMenu()" class="fixed inset-0"></div>
      <!--
        Off-canvas menu, show/hide based on off-canvas menu state.
      -->
      <div @translateX *ngIf='isOffCanvasMenu' class="relative mr-16 flex w-full max-w-xs flex-1">
        <!--
          Close button, show/hide based on off-canvas menu state.
        -->
        <div @opacityInOut *ngIf='isOffCanvasMenu' class="absolute left-full top-0 flex w-16 justify-center pt-5">
          <button type="button" (click)="toggleOffCanvasMenu()" class="-m-2.5 p-2.5">
            <span class="sr-only">Close sidebar</span>
            <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
          <div class="flex h-16 shrink-0 items-center">
            <img class="h-8 w-auto" src="/assets/svg/logo.svg" alt="Vestra">
          </div>
          <nav class="flex flex-1 flex-col">
            <ul role="list" class="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" class="-mx-2 space-y-1">
                  <li *ngFor="let link of side_links">
                    <!-- Current: "bg-gray-50 text-stone-600", Default: "text-gray-700 hover:text-stone-600 hover:bg-gray-50" -->
                    <a (click)="this.navigate(link);toggleOffCanvasMenu()" [ngClass]="link.active ? 'bg-gray-50 text-stone-600' : 'text-gray-700 hover:text-stone-600 hover:bg-gray-50'" class="group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold">
                      <svg [ngClass]="link.active ? 'text-stone-600' : 'text-gray-700'" class="h-6 w-6 shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" [attr.d]="link.svg_path" />
                      </svg>
                      {{link.name}}
                    </a>
                  </li>
                </ul>
              </li>
              <!--              <li class="mt-auto">-->
              <!--                <a href="#" class="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-stone-600">-->
              <!--                  <svg class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-stone-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">-->
              <!--                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />-->
              <!--                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />-->
              <!--                  </svg>-->
              <!--                  Settings-->
              <!--                </a>-->
              <!--              </li>-->
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <!-- Static sidebar for desktop -->
  <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
    <!-- Sidebar component, swap this element with another sidebar if you like -->
    <div class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
      <div class="flex h-16 shrink-0 items-center">
        <img class="h-8 w-auto" src="/assets/svg/logo.svg" alt="Vestra">
      </div>
      <nav class="flex flex-1 flex-col">
        <ul role="list" class="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" class="-mx-2 space-y-1">
              <li *ngFor="let link of side_links">
                <!-- Current: "bg-gray-50 text-stone-600", Default: "text-gray-700 hover:text-stone-600 hover:bg-gray-50" -->
                <a (click)="this.navigate(link)" [ngClass]="link.active ? 'bg-gray-50 text-stone-600' : 'text-gray-700 hover:text-stone-600 hover:bg-gray-50'" class="group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold">
                  <svg [ngClass]="link.active ? 'text-stone-600' : 'text-gray-700'" class="h-6 w-6 shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" [attr.d]="link.svg_path" />
                  </svg>
                  {{link.name}}
                </a>
              </li>
            </ul>
          </li>
          <!--          <li class="mt-auto">-->
          <!--            <a href="#" class="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-stone-600">-->
          <!--              <svg class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-stone-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">-->
          <!--                <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />-->
          <!--                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />-->
          <!--              </svg>-->
          <!--              Paramètres-->
          <!--            </a>-->
          <!--          </li>-->
        </ul>
      </nav>
    </div>
  </div>

  <div class="lg:pl-72">
<!--    <div class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">-->
<!--      <button type="button" (click)="toggleOffCanvasMenu()" class="-m-2.5 p-2.5 text-gray-700 lg:hidden">-->
<!--        <span class="sr-only">Open sidebar</span>-->
<!--        <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">-->
<!--          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />-->
<!--        </svg>-->
<!--      </button>-->

<!--      &lt;!&ndash; Separator &ndash;&gt;-->
<!--      <div class="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true"></div>-->

<!--      <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">-->
<!--        <div class="flex items-center gap-x-4 lg:gap-x-6">-->
<!--          &lt;!&ndash; Profile dropdown &ndash;&gt;-->
<!--          <app-user-avatar></app-user-avatar>-->
<!--        </div>-->
<!--        <div *ngIf='isMenu' (click)="toggleMenu()" class="fixed inset-0"></div>-->
<!--      </div>-->
<!--    </div>-->

    <main class="pb-10 mt-28">
      <div class="px-4 sm:px-6 lg:px-8">
        <router-outlet></router-outlet>
      </div>
    </main>
  </div>
</div>




