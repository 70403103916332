
  <div class="mt-8 mx-auto max-w-4xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
    <h1 class="text-3xl font-bold tracking-tight text-gray-900">Panier</h1>

    <form class="mt-12">
      <div>
        <h2 class="sr-only">Articles dans votre panier</h2>

        <ul role="list" class="divide-y divide-gray-200 border-b border-t border-gray-200">
          <li *ngFor="let article of cart.articles" class="flex py-6 sm:py-10">
            <div class="flex-shrink-0">
              <img src="/assets/img/article/{{article.id! % 4}}.jpg" alt="{{article.description}}" class="h-24 w-24 rounded-lg object-cover object-center sm:h-32 sm:w-32">
            </div>

            <div class="relative ml-4 flex flex-1 flex-col justify-between sm:ml-6">
              <div>
                <div class="flex justify-between sm:grid sm:grid-cols-2">
                  <div class="pr-6">
                    <p class="mt-1 text-sm text-gray-500">{{article.brand}}</p>
                    <h3 class="text-sm">
                      <a href="#" class="font-medium text-gray-700 hover:text-gray-800">{{article.name}}</a>
                    </h3>
                    <p class="mt-1 text-sm text-gray-500">{{article.color}} / {{article.size}}</p>
                  </div>

                  <p class="text-right text-sm font-medium text-gray-900"><span class="line-through mt-1 text-sm text-gray-500">{{article.old_price}} CHF</span>&nbsp;<span>{{article.price}} CHF</span></p>
                </div>

                <div class="mt-4 flex items-center sm:absolute sm:left-1/2 sm:top-0 sm:mt-0 sm:block">
                  <label for="quantity-1" class="sr-only">Quantité,{{article.name}}</label>
                  <select id="quantity-1" name="quantity-1" class="block max-w-full rounded-md border border-gray-300 py-1.5 text-left text-base font-medium leading-5 text-gray-700 shadow-sm focus:border-stone-500 focus:outline-none focus:ring-1 focus:ring-stone-500 sm:text-sm">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                  </select>

                  <button type="button" class="ml-4 text-sm font-medium text-stone-600 hover:text-stone-500 sm:ml-0 sm:mt-3">
                    <span>Supprimer</span>
                  </button>
                </div>
              </div>

              <p class="mt-4 flex space-x-2 text-sm text-gray-700">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 flex-shrink-0 text-green-400">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
                <span>En stock</span>
              </p>
<!--              <p class="mt-4 flex space-x-2 text-sm text-gray-700">-->
<!--                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 flex-shrink-0 text-orange-400">-->
<!--                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />-->
<!--                </svg>-->
<!--                <span>Cet article est également présent dans les paniers d'autres utilisateurs.</span>-->
<!--              </p>-->

<!--              <p class="mt-4 flex space-x-2 text-sm text-gray-700">-->
<!--                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 flex-shrink-0 text-red-400">-->
<!--                  <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />-->
<!--                </svg>-->
<!--                <span>En rupture de stock</span>-->
<!--              </p>-->
            </div>
          </li>
        </ul>
      </div>

      <!-- Order summary -->
      <div class="mt-10 sm:ml-32 sm:pl-6">
        <div class="rounded-lg bg-white px-4 py-6 sm:p-6 lg:p-8">
          <h2 class="sr-only">Résumé de la commande</h2>

          <div class="flow-root">
            <dl class="-my-4 divide-y divide-gray-200 text-sm">
              <div class="flex items-center justify-between py-4">
                <dt class="text-gray-600">Sous-total</dt>
                <dd class="font-medium text-gray-900">{{cart.total}} CHF</dd>
              </div>
              <div class="flex items-center justify-between py-4">
                <dt class="text-gray-600">Réduction grâce à Vestra</dt>
                <dd class="font-medium text-gray-900">{{cart.total}} CHF</dd>
              </div>
<!--              <div class="flex items-center justify-between py-4">-->
<!--                <dt class="text-gray-600">Livraison</dt>-->
<!--                <dd class="font-medium text-gray-900">$5.00</dd>-->
<!--              </div>-->
<!--              <div class="flex items-center justify-between py-4">-->
<!--                <dt class="text-gray-600">TVA</dt>-->
<!--                <dd class="font-medium text-gray-900">$8.32</dd>-->
<!--              </div>-->
              <div class="flex items-center justify-between py-4">
                <dt class="text-base font-medium text-gray-900">Total de la commande</dt>
                <dd class="text-base font-medium text-gray-900">{{cart.total}} CHF</dd>
              </div>
            </dl>
          </div>
        </div>
        <div class="mt-10">
          <button (click)="nav.goToCheckout()" type="submit" class="w-full rounded-md border border-transparent bg-stone-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2 focus:ring-offset-gray-50">Passer en caisse</button>
        </div>

        <div class="mt-6 text-center text-sm text-gray-500">
          <p>
            or
            <a href="#" class="font-medium text-stone-600 hover:text-stone-500">
              Continuer mes achats
              <span aria-hidden="true"> &rarr;</span>
            </a>
          </p>
        </div>
      </div>
    </form>
  </div>
