<div class="relative z-50"
     aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
  <!--
    Background backdrop, show/hide based on slide-over state.

    Entering: "ease-in-out duration-500"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in-out duration-500"
      From: "opacity-100"
      To: "opacity-0"
  -->
  <div @opacity *ngIf='cartOpen' (click)="toggle_basket()" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity "></div>

<!--  <div class="fixed inset-0 overflow-hidden">-->
<!--    <div class="absolute inset-0 overflow-hidden">-->
      <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
        <!--
          Slide-over panel, show/hide based on slide-over state.

          Entering: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-full"
            To: "translate-x-0"
          Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-0"
            To: "translate-x-full"
        -->
        <div @translateY *ngIf='cartOpen' class="pointer-events-auto w-screen max-w-md z-50">
          <div *ngIf="cart.articles.length > 0" class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
            <div class="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
              <div class="flex items-start justify-between">
                <h2 class="text-lg font-medium text-gray-900" id="slide-over-title">Panier</h2>
                <div class="ml-3 flex h-7 items-center">
                  <button type="button" (click)="toggle_basket()" class="relative -m-2 p-2 text-gray-400 hover:text-gray-500">
                    <span class="absolute -inset-0.5"></span>
                    <span class="sr-only">Close panel</span>
                    <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>

              <div class="mt-8">
                <div class="flow-root">
                  <ul role="list" class="-my-6 divide-y divide-gray-200">
                    <li *ngFor="let article of cart.articles" class="flex py-6">
                      <div class="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                        <img src="/assets/img/article/{{article.id! % 4}}.jpg" alt="{{article.description}}" class="h-full w-full object-cover object-center">
                      </div>

                      <div class="ml-4 flex flex-1 flex-col">
                        <div>

                          <div class="flex justify-between text-base font-medium text-gray-900">
                            <h3>
                              <a href="#"><span class="mt-1 text-sm text-gray-500">{{article.brand}}</span> {{article.name}}</a>
                            </h3>
                            <p class="ml-4"><span class="line-through mt-1 text-sm text-gray-500">{{article.old_price}} CHF</span>&nbsp;<span>{{article.price}} CHF</span></p>
                          </div>
                          <p class="mt-1 text-sm text-gray-500">{{article.color}} / {{article.size}}</p>
                          <p class="mt-1 flex space-x-2 text-sm text-gray-500">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 flex-shrink-0 text-green-400">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>

                            <span>En stock</span>
                          </p>
                        </div>
                        <div class="flex flex-1 items-end justify-between text-sm">
<!--                          <p class="text-gray-500">Qty 1</p>-->
                          <label for="quantity-1" class="sr-only">Quantity, Basic Tee</label>
                          <select [value]='article.quantity' (change)="changingQuantity($event, article)"
                                  id="quantity-1" name="quantity-1"
                                  class="pl-0 block max-w-full border border-white py-0.5 text-left text-base font-medium leading-5 text-gray-700 sm:text-sm focus:border-stone-500 focus:outline-none focus:ring-1 focus:ring-white">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                          </select>

                          <div class="flex">
                            <button (click)="removeArticle(article)" type="button" class="font-medium text-stone-600 hover:text-stone-500">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="-ml-0.5 h-5 w-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                              </svg>
<!--                              Supprimer-->
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="border-t border-gray-200 px-4 py-6 sm:px-6">
              <div class="flex justify-between text-base font-medium text-gray-900">
                <p>Total</p>
                <p>{{cart.total}} CHF</p>
              </div>
<!--              <p class="mt-0.5 text-sm text-gray-500">Shipping and taxes calculated at checkout.</p>-->
              <div class="mt-6">
                <a (click)="goToCart()" class="btn">Voir le panier</a>
              </div>
              <div class="mt-6 flex justify-center text-center text-sm text-gray-500">
                <p>
                  ou
                  <button (click)="toggle_basket()"
                          type="button" class="font-medium text-stone-600 hover:text-stone-500">
                    Continuer mes achats
                    <span aria-hidden="true"> &rarr;</span>
                  </button>
                </p>
              </div>
            </div>
          </div>





          <div *ngIf="cart.articles.length == 0" class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
            <div class="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
              <div class="flex items-start justify-between">
                <h2 class="text-lg font-medium text-gray-900" id="slide-over-title">Panier</h2>
                <div class="ml-3 flex h-7 items-center">
                  <button type="button" (click)="toggle_basket()" class="relative -m-2 p-2 text-gray-400 hover:text-gray-500">
                    <span class="absolute -inset-0.5"></span>
                    <span class="sr-only">Close panel</span>
                    <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>

              <div class="mt-8">
                <div class="flow-root">
                  <div>Votre panier est vide :(</div>
                </div>
              </div>


            </div>
          </div>

        </div>
      </div>
<!--    </div>-->
<!--  </div>-->
</div>
