import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Article, Basket} from "../../../../../../fe-global-components/interfaces/interfaces";
import {NavigationService} from "../../../shared/services/navigation.service";
import {CartService} from "../../../shared/services/cart.service";
import {NgForOf} from "@angular/common";

@Component({
  selector: 'app-cart-page',
  standalone: true,
  imports: [
    NgForOf
  ],
  templateUrl: './cart-page.component.html',
  styleUrl: './cart-page.component.scss'
})
export class CartPageComponent implements OnInit {
  cart: Basket = { id: 0, total: 0.00, articles: [] };

  constructor(protected nav: NavigationService,
              private cartService: CartService) {
  }

  ngOnInit(): void {
    this.cartService.currentCart.subscribe(currentCart => this.cart = currentCart);
  }

  removeArticle(selected_article: Article) {
    this.cartService.removeArticle(selected_article);
  }

  changingQuantity(event: any, article: Article) {
    this.cartService.changeQuantity(article, event.target!['value']);
  }

}
