<section class="bg-stone-100 py-52">
<div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-sm">
    <img class="mx-auto h-10 w-auto" src="/assets/svg/logo.svg" alt="Vestra">
    <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Créer un compte</h2>
  </div>

  <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
    <form class="space-y-6" [formGroup]="register_form">
      <div>
        <label for="email" class="label">Adresse email</label>
        <div class="mt-2">
          <input id="email" name="email" type="email"
                 formControlName="email"
                 autocomplete="email" required class="input">
        </div>
      </div>

      <div>
        <label for="firstname" class="label">firstname email</label>
        <div class="mt-2">
          <input id="firstname" name="firstname" type="firstname"
                 formControlName="firstname"
                 autocomplete="firstname" required class="input">
        </div>
      </div>

      <div>
        <label for="lastname" class="label">lastname email</label>
        <div class="mt-2">
          <input id="lastname" name="lastname" type="lastname"
                 formControlName="lastname"
                 autocomplete="lastname" required class="input">
        </div>
      </div>

      <div>
        <div class="flex items-center justify-between">
          <label for="password" class="label">Mot de passe</label>
        </div>
        <div class="mt-2">
          <input id="password" name="password" type="password"
                 formControlName="password"
                 autocomplete="current-password" required class="input">
        </div>
      </div>

      <div>
        <div class="flex items-center justify-between">
          <label for="password" class="label">Répéter le mot de passe</label>
        </div>
        <div class="mt-2">
          <input id="re_password" name="password" type="password"
                 formControlName="password_confirmation"
                 autocomplete="current-password" required class="input">
        </div>
      </div>

      <div>
        <button type="submit" (click)="submit()" class="btn">S'inscrire</button>
      </div>
    </form>

    <p class="mt-10 text-center text-sm text-gray-500">
      Vous avez déjà un compte ?
      <a href="/login" class="link">Se connecter</a>
    </p>
  </div>
</div>

</section>
