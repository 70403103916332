<div class="relative">
  <button type="button" (click)="toggleMenu()" class="-m-1.5 flex items-center p-1.5" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
    <span class="sr-only">Open user menu</span>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
    </svg>
  </button>
  <!--
    Dropdown menu, show/hide based on menu state.

    Entering: "transition ease-out duration-100"
      From: "transform opacity-0 scale-95"
      To: "transform opacity-100 scale-100"
    Leaving: "transition ease-in duration-75"
      From: "transform opacity-100 scale-100"
      To: "transform opacity-0 scale-95"
  -->
  <div @opacityScale *ngIf="isMenu" (click)="toggleMenu()" class="z-30  fixed inset-0 bg-gray-500 bg-opacity-0 transition-opacity"></div>

  <div @opacityScale *ngIf="isMenu"
       class="absolute right-0 z-40 mt-2.5 w-40 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
    <!-- Active: "bg-gray-50", Not Active: "" -->
    <a *ngIf="user.first_name != ''" (click)="goToProfile()" class="cursor-pointer block px-3 py-1 text-sm leading-6 text-gray-900" role="menuitem" tabindex="-1" id="user-menu-item-0">Mon compte</a>
    <a *ngIf="user.first_name != ''" (click)="goToMyOrders() " class="cursor-pointer block px-3 py-1 text-sm leading-6 text-gray-900" role="menuitem" tabindex="-1" id="user-menu-item-1">Mes commandes</a>
    <a *ngIf="user.first_name != ''" (click)="goToLogOut()" class="cursor-pointer block px-3 py-1 text-sm leading-6 text-gray-900" role="menuitem" tabindex="-1" id="user-menu-item-2">Déconnexion</a>
    <a *ngIf="user.first_name == ''" (click)="goToLogin()" class="cursor-pointer block px-3 py-1 text-sm leading-6 text-gray-900" role="menuitem" tabindex="-1" id="user-menu-item-3">Connexion</a>
  </div>
</div>
