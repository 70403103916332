import { Injectable } from '@angular/core';
import { BffApiService } from "../api/bff-api.service";
import { User } from "../../../../../fe-global-components/interfaces/interfaces";
import { credentials } from "../../../../../fe-global-components/interfaces/api_forms";
import { BehaviorSubject } from "rxjs";
import { NavigationService } from "./navigation.service";
import { Role } from "../../../../../fe-global-components/interfaces/enums";
import { user } from "../../../../../fe-global-components/constants/init-const";
import { SnackBarService, successLogin, successLogout, successDeleteAccount } from "./snack/snack-bar.service";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userSource = new BehaviorSubject<User>(user);
  currentUser = this.userSource.asObservable();
  constructor(private bff: BffApiService,
    private nav: NavigationService,
    private snack: SnackBarService) {
    this.getProfilInfos()
  }

  login(credential: credentials) {
    this.bff.login(credential).subscribe((data: any) => {
      this.getProfilInfos()
      this.snack.snack(successLogin)
      this.nav.goToShop()
    })
  }

  logout() {
    this.bff.logout().subscribe((data: any) => {
      this.snack.snack(successLogout)
      this.nav.goToShop()
      this.userSource.next({ first_name: "", last_name: ""})
    })
  }


  register(value: any) {
    this.bff.register(value).subscribe({
      next: (data) => {
        console.log(data)
        this.snack.snack('Vous êtes connecté')
        this.nav.goToCheckEmail(value.email)
      },
      error: (err) => {
        console.error(err); // Log the error if needed
      },
    })
  }

  changePWD(values: any) {
    // TODO
    this.bff.updatePassword(values).subscribe(data => {
      console.log(data)
      this.snack.snack('Mot de passe mis à jour')
    })
  }

  updatePWD(values: any) {
    this.bff.resetPassword(values).subscribe(data => {
      console.log(data)
    })
  }


  deleteAccount() {
    this.bff.deleteAccount().subscribe(data => {
      console.log(data)
      this.snack.snack(successDeleteAccount)
      this.nav.goToHome()
    })
  }


  /**
   * The Role returned can be read by using
   *
   * "then" :
   *
   * this.getProfilInfos().then((role) => {
   *   console.log('Role:', role);
   * });
   *
   * or "async/await":
   *
   * async someMethod() {
   *   const role = await this.getProfilInfos();
   *   console.log('Role:', role);
   * }
   */
  getProfilInfos(): Promise<Role> {
    return new Promise((resolve, reject) => {
      this.bff.getProfilInfos().subscribe({
        next: (userValues) => {

          userValues.email = this.maskEmail(userValues.email)
          this.userSource.next(userValues);
          resolve(Role.USER);
        },
        error: (err) => {
          console.error(err); // Log the error if needed
          resolve(Role.VISITOR);
        },
      });
    });
  }

  sendVerificationEmail() {
    this.bff.getToken().subscribe(data => { //TODO is this correct ? @ARKAR
      this.bff.sendVerificationEmail().subscribe(data => {
        console.log(data)
        this.snack.snack('Un mail de verification vous a été envoyé. Veuillez suivre les instructions du mail.')
      })
    })
  }

  getUser(): User {
    return this.userSource.value
  }

  maskEmail(email: string): string {
    // Split the email into local part and domain
    const [localPart, domain] = email.split('@');

    if (localPart.length <= 1) {
      // If the local part is too short, just return the original email
      return email;
    }

    // Mask the local part (keeping the first character and replacing the rest with asterisks)
    const maskedLocalPart = localPart[0] + '*'.repeat(localPart.length - 1);

    // Return the masked email
    return `${maskedLocalPart}@${domain}`;
  }

}

