import { Component } from '@angular/core';
import {environment} from "../../../environments/environment";
import {MatDialog} from "@angular/material/dialog";
import {PopupComponent} from "../../component/popup-components/popup/popup.component";

@Component({
  selector: 'app-page-api',
  templateUrl: './page-api.component.html',
  styleUrl: './page-api.component.scss'
})
export class PageApiComponent {
  protected readonly environment = environment;

  constructor(public dialog: MatDialog) {
  }

  openPopup() {
    const dialogRef = this.dialog.open(PopupComponent);

    dialogRef.afterClosed().subscribe(result => {});
  }
}
