import { Component } from '@angular/core';
import { ShopMenuService } from "../../shared/services/shop-menu.service";
import { categories } from '../../../../../fe-global-components/constants/menu-shopping-links';
import { BffApiService } from 'src/app/shared/api/bff-api.service';


@Component({
  selector: 'app-filter-search-article',
  templateUrl: './filter-search-article.component.html'
})
export class FilterSearchArticleComponent {
  categories: any[] = [] // dynamic categories according to selected menu item 
  search_categories: any[] = categories // color, size, etc.

  constructor(
    private shopMenu: ShopMenuService,
    private bff: BffApiService
  ) {
    this.shopMenu.currentCategories.subscribe(categories => {
      this.categories = categories[0]
    })
    this.shopMenu.currentFilters.subscribe(filter => {
      Object.keys(filter).forEach(e => {
        console.log(filter[e])
      });
    });

    this.bff.getColors().subscribe(data => console.log(data))
    this.bff.getSizes().subscribe(data => console.log(data))
  }

  navigateToCategory(category: any) {
    this.shopMenu.updateLink(category)
  }


  // TODO : add filter logic


}
