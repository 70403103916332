
<div class="py-16 sm:py-24">
  <div class="mx-auto max-w-7xl sm:px-2 lg:px-8">
    <div class="mx-auto max-w-2xl px-4 lg:max-w-4xl lg:px-0">
      <h1 class="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">Gérer mon compte</h1>
      <p class="mt-2 text-sm text-gray-500">Mes informations personnels et préférences.</p>
    </div>


    <ul role="list" class="divide-y divide-gray-100 mt-8 mx-auto max-w-2xl px-4 lg:max-w-4xl lg:px-0">
      <li class="flex items-center justify-between gap-x-6 py-5 bg-white px-4 divide-y divide-gray-100">
        <div class="min-w-0">
          <div class="flex items-start gap-x-3">
            <h3 class="text-base font-semibold leading-7 text-gray-900">Mon identité</h3>
            <p class="rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset text-green-700 bg-green-50 ring-green-600/20">Verifié</p>
          </div>
          <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
            <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{{user.firstname}} {{user.lastname}}</p>
          </div>
        </div>
        <div class="flex flex-none items-center gap-x-4">
          <button (click)="sendVerificationEmail()" class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">Verifier à nouveau</button>
        </div>
      </li>
      <li class="flex items-center justify-between gap-x-6 py-5 bg-white px-4 divide-y divide-gray-100">
        <div class="min-w-0">
          <div class="flex items-start gap-x-3">
            <h3 class="text-base font-semibold leading-7 text-gray-900">Mon email</h3>
            <p class="rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset text-green-700 bg-green-50 ring-green-600/20">Verifié</p>
          </div>
          <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
            <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{{user.email}}</p>
          </div>
        </div>
        <div class="flex flex-none items-center gap-x-4">
          <button (click)="sendVerificationEmail()" class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">Verifier à nouveau</button>
        </div>
      </li>
      <li class="flex items-center justify-between gap-x-6 py-5 bg-white px-4">
        <div class="min-w-0">
          <div class="flex items-start gap-x-3">
            <h3 class="text-base font-semibold leading-7 text-gray-900">Modifier le mot de passe</h3>

          </div>
          <div class="mt-1 flex flex-col gap-x-2 text-xs leading-5 text-gray-500">
            <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">***********</p>
            <div *ngIf="isChangePWD">
              <form class="space-y-6" [formGroup]="changePassword" >
                <div>
                  <label for="email" class="label">Mot de passe actuel</label>
                  <div class="mt-2">
                    <input id="old_password" name="old_password" type="password"
                           formControlName="old_password"
                          required class="input">
                  </div>
                </div>

                <div>
                  <div class="flex items-center justify-between">
                    <label for="password" class="label">Nouveau mot de passe</label>
                  </div>
                  <div class="mt-2">
                    <input id="password" name="password" type="password"
                           formControlName="password"
                          required class="input">
                  </div>
                </div>

                <div>
                  <div class="flex items-center justify-between">
                    <label for="password" class="label">Répéter le nouveau mot de passe</label>
                  </div>
                  <div class="mt-2">
                    <input id="password_confirmation" name="password" type="password"
                           formControlName="password_confirmation"
                            required class="input">
                  </div>
                </div>

                <div>
                  <button type="submit" (click)="submit()" class="btn">Changer le mot de passe</button>           <button (click)="togglePWD()"  class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">Annuler</button>

                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="flex flex-none items-center gap-x-4">
          <a (click)="togglePWD()"  *ngIf="!isChangePWD" class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">Modifier</a>
        </div>
      </li>
      <li class="flex items-center justify-between gap-x-6 py-5 bg-white px-4">
        <div class="min-w-0">
          <div class="flex items-start gap-x-3">
            <h3 class="text-base font-semibold leading-7 text-gray-900">Supprimer le compte</h3>
          </div>
          <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
            <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Le compte ne peut plus être récupéré une fois supprimé.</p>
          </div>
        </div>
        <div class="flex flex-none items-center gap-x-4">
          <a (click)="nav.goToDeleteAccount()" class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">Supprimer définitivement</a>
        </div>
      </li>
    </ul>


  </div>
</div>
