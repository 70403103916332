

<div class="overflow-hidden lg:py-40 mt-32">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
      <div class="lg:pr-8 lg:pt-4">
        <div class="lg:max-w-lg">
          <h2 class="text-base font-semibold leading-7 text-stone-600">Logiciels partenaires</h2>
          <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Nous supportons les logiciels de caisse suivants</p>
          <p class="mt-6 text-lg leading-8 text-gray-600">Afin de fluidifier la gestion des stocks entre les magasins et la plateforme Vestra, nous avons construit des partenariats avec différents fournisseurs  de logiciels de caisse. </p>
          <dl class="my-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
            <div class="relative pl-9">
              <dt class="inline font-semibold text-gray-900">
                <a target="_blank" href="https://www.prisme.ch/" class="cursor-pointer">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="absolute left-1 top-1 h-5 w-5 text-stone-600">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                  </svg>
                  BC Prisme
                </a>
              </dt>
<!--              <dd class="inline">Télécharger l’API</dd>-->
            </div>
            <div class="relative pl-9">
              <dt class="inline font-semibold text-gray-900">
                <a target="_blank" href="https://www.cashflow.ch/">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="absolute left-1 top-1 h-5 w-5 text-stone-600">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                </svg>
                CashFlow
                </a>
              </dt>
<!--              <dd class="inline">Télécharger l’API</dd>-->
            </div>
          </dl>

          <a (click)="openPopup()" class="group block mx-auto rounded-lg p-6 bg-white ring-1 ring-slate-900/5 shadow-lg space-y-3 hover:bg-stone-500 hover:ring-stone-500">
            <div class="flex items-center space-x-3">
              <h3 class="text-slate-900 group-hover:text-white text-sm font-semibold">Autre logiciel de caisse ?</h3>
            </div>
            <p class="text-slate-500 group-hover:text-white text-sm">N’hésitez pas à nous contacter si vous avez besoin d’assistance pour un autre logiciel de caisse !</p>
          </a>
        </div>
      </div>
      <img src="/assets/img/photo-1556742504-16b083241fab.jpg" alt="Product screenshot" class="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0" width="2432" height="1442">
    </div>
  </div>
</div>



<div class="mx-auto max-w-7xl px-6 py-12 lg:px-8">
  <div class="lg:grid lg:grid-cols-12 lg:gap-8">
    <div class="lg:col-span-5">
      <h2 class="text-2xl font-bold leading-10 tracking-tight text-gray-900">Marche à suivre</h2>
      <!--        <p class="mt-4 text-base leading-7 text-gray-600">Can’t find the answer you’re looking for? Reach out to our <a href="#" class="font-semibold text-stone-600 hover:text-stone-500">customer support</a> team.</p>-->
    </div>
    <div class="mt-10 lg:col-span-7 lg:mt-0">
      <dl class="space-y-10">
        <div>
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <ol class="list-decimal">
              <li>Inscrivez-vous comme commerçant sur Vestra</li>
              <li>Choisissez l’API correspondant à votre logiciel de caisse </li>
              <li>Laissez Vestra paramétrer le logiciel</li>
              <li>Vendez vos articles !</li>
            </ol>
          </dt>
          <dd class="mt-2 text-base leading-7 text-gray-600">Pour télécharger les APIs, veuillez vous inscrire comme magasin partenaire
            <div class="mt-10 flex items-center gap-x-6">
              <a href="{{environment.retailerUrl}}" target="_blank" class="rounded-md bg-stone-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-stone-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600">S'inscrire</a>
              <a href="mailto:info@vestrashop.ch" target="_blank" class="text-sm font-semibold leading-6 text-gray-900">Prendre contact <span aria-hidden="true">→</span></a>
            </div>
          </dd>
        </div>

        <!-- More questions... -->
      </dl>
    </div>
  </div>
</div>


<div class="relative isolate overflow-hidden">
  <div class="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 py-24 sm:py-32">
    <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-8">
      <div class="mt-24 sm:mt-32 lg:mt-16">
        <div class="inline-flex space-x-6">
          <span class="rounded-full bg-stone-600/10 px-3 py-1 text-sm font-semibold leading-6 text-stone-600 ring-1 ring-inset ring-stone-600/10">API</span>
          <!--          <span class="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-600">-->
          <!--            <span>Just shipped v1.0</span>-->
          <!--            <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">-->
          <!--              <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />-->
          <!--            </svg>-->
          <!--          </span>-->
        </div>
      </div>
      <h1 class="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Qu’est-ce que c’est ?</h1>
      <p class="mt-6 text-base leading-8 text-gray-600">Une API, ou Interface de Programmation Applicative, est un ensemble de protocoles et d'outils qui permettent à différentes applications logicielles de communiquer entre elles.
        <br>Dans le contexte de Vestra, notre API joue un rôle essentiel en facilitant la connexion transparente entre la plateforme et les systèmes de caisse des magasins.
        <br><br>En connectant le système de caisse à notre plateforme via une API, nous permettons aux magasins revendeurs une gestion coordonnée de leurs articles entre leurs stocks et la plateforme Vestra.</p>
      <!--      <div class="mt-10 flex items-center gap-x-6">-->
      <!--        <a href="#" class="rounded-md bg-stone-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-stone-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600">Get started</a>-->
      <!--        <a href="#" class="text-sm font-semibold leading-6 text-gray-900">Learn more <span aria-hidden="true">→</span></a>-->
      <!--      </div>-->
    </div>
    <div class="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
      <div class="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
        <div class="-m-2 rounded-xl p-2 lg:-m-4 lg:rounded-2xl lg:p-4">
          <img src="/assets/img/csm_B_FFF_13773_a56985abf1-copie.jpg" alt="App screenshot" width="2432" height="1442"
               class="w-[76rem] rounded-md shadow-2xl ring-1 ring-gray-900/10 object-cover" style="max-height: 700px">
        </div>
      </div>
    </div>
  </div>
</div>
