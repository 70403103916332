<div class="relative z-10" role="dialog" aria-modal="true">
  <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
  <div class="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block"></div>

  <div class="fixed inset-0 z-10 overflow-y-auto">
    <div class="fixed inset-0"></div>
    <div class="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">&#8203;</span>

      <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          To: "opacity-100 translate-y-0 md:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 md:scale-100"
          To: "opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
      -->
      <div class="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
        <div class="relative flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
          <button type="button" (click)="onNoClick()" class="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8">
            <span class="sr-only">Fermer</span>
            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>

          <div class="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-12 lg:items-center lg:gap-x-8">
            <div class="aspect-h-3 aspect-w-2 overflow-hidden rounded-lg bg-gray-100 sm:col-span-4 lg:col-span-5">
              <img src="/assets/img/article/{{product.id! % 4}}.jpg" alt="Back of women&#039;s Basic Tee in black." class="object-cover object-center">
            </div>
            <div class="sm:col-span-8 lg:col-span-7">
              <span class="uppercase text-xs truncate block mt-2">{{product.brand}}</span>
              <h2 class="text-xl font-medium text-gray-900 sm:pr-12">{{product.name}}</h2>

              <section aria-labelledby="information-heading" class="mt-1">
                <h3 id="information-heading" class="sr-only">Information sur le produit</h3>

                <p class="font-medium text-gray-900"><span class="line-through">{{product.old_price}} CHF</span>&nbsp;<span>{{product.price}} CHF</span> </p>
              </section>

              <section aria-labelledby="options-heading" class="mt-8">
                <h3 id="options-heading" class="sr-only">Option du produit</h3>

                <form [formGroup]="article_form">
                  <!-- Color picker -->
                  <div>
                    <h4 class="text-sm font-medium text-gray-900">Couleur</h4>

                    <fieldset class="mt-2">
                      <legend class="sr-only">Choisisez une couleur</legend>
                      <div class="flex items-center space-x-3">
                        <!--
                          Active and Checked: "ring ring-offset-1"
                          Not Active and Checked: "ring-2"
                        -->
                        <label *ngFor="let color of colors; let i=index"
                               [ngClass]="{
                                  'ring': currentColor.id === color.id,
                                  'ring-offset-1': currentColor.id === color.id,
                                  'ring-2': currentColor.id === color.id}"
                               [class]="color.ringColor" class="relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none">
                          <input type="radio" formControlName="color" (click)="selectColor(color)" name="color" value="{{ color.name }}" class="sr-only" attr.aria-labelledby="color-choice-{{i}}-label">
                          <span id="color-choice-{{i}}-label" class="sr-only">{{ color.name }}</span>
                          <span aria-hidden="true" [style.background-color]="color.backgroundColor" class="h-8 w-8 rounded-full border border-black border-opacity-10"></span>
                        </label>
                      </div>
                    </fieldset>
                  </div>

                  <!-- Size picker -->
                  <div class="mt-8">
                    <div class="flex items-center justify-between">
                      <h4 class="text-sm font-medium text-gray-900">Taille</h4>
                      <a href="/assets/img/sizeguide-clothes-en.jpg" target="_blank" class="text-sm font-medium text-stone-600 hover:text-stone-500">Guide des tailles</a>
                    </div>

                    <fieldset class="mt-2">
                      <legend class="sr-only">Choisi une taille</legend>
                      <div class="grid grid-cols-7 gap-2">
                        <!--
                          In Stock: "cursor-pointer", Out of Stock: "opacity-25 cursor-not-allowed"
                          Active: "ring-2 ring-stone-500 ring-offset-2"
                          Checked: "border-transparent bg-stone-600 text-white hover:bg-stone-700", Not Checked: "border-gray-200 bg-white text-gray-900 hover:bg-gray-50"
                        -->
                        <label *ngFor="let size of sizes; let i=index"
                               [ngClass]="{
                            'cursor-pointer': size.available,
                            'cursor-not-allowed': !size.available,
                            'opacity-25': !size.available,
                            'ring-2': currentSize.id === size.id,
                            'ring-offset-2': currentSize.id === size.id,
                            'ring-stone-500': currentSize.id === size.id,
                            'bg-stone-600': currentSize.id === size.id,
                            'border-transparent': currentSize.id === size.id,
                            'text-white': currentSize.id === size.id,
                            'hover:bg-stone-700': currentSize.id === size.id,
                            'bg-white': currentSize.id !== size.id,
                            'border-gray-200': currentSize.id !== size.id,
                            'text-gray-900': currentSize.id !== size.id,
                            'hover:bg-gray-50': currentSize.id !== size.id
                          }"
                               (click)="selectSize(size)"
                               class="border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1 cursor-pointer focus:outline-none">
                          <input formControlName="size" type="radio" name="size" value="{{ size.name }}" class="sr-only" attr.aria-labelledby="size-choice-{{i}}-label">
                          <span id="size-choice-{{i}}-label">{{ size.name }}</span>
                        </label>
                      </div>
                    </fieldset>
                  </div>

                  <button (click)="cartService.addArticle(this.article_form, product);"
                          class="mt-8 flex w-full items-center justify-center rounded-md border border-transparent bg-stone-600 px-8 py-3 text-base font-medium text-white hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2">Ajouter au panier</button>

                  <p class="absolute left-4 top-4 text-center sm:static sm:mt-8">
                    <a (click)="this.goToArticle()" class="font-medium text-stone-600 hover:text-stone-500">Plus de détails</a>
                  </p>
                </form>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
