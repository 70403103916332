import {Component, Input} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-plus-minus',
  templateUrl: './plus-minus.component.html',
  styleUrls: ['./plus-minus.component.scss'],
  animations: [
    trigger('rotate', [
      transition(':enter', [
        style({ transform: 'rotateX(0)' }),
        animate('300ms ease-in-out', style({  transform: 'rotateX(0.5turn)' }))
      ]),
      transition(':leave', [
        style({ transform: 'rotateX(0.5turn)' }),
        animate('300ms ease-in-out', style({ transform: 'rotateX(0)' }))
      ])
    ]),
  ],
})
export class PlusMinusComponent {
  public icon = 'minimize';
  @Input() isOpen!: boolean
}
