  <!-- Hero section -->
  <div class="relative isolate -z-10 overflow-hidden bg-gradient-to-b from-stone-100/20 pt-14">
    <div class="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-stone-600/10 ring-1 ring-stone-50 sm:-mr-80 lg:-mr-96" aria-hidden="true"></div>
    <div class="relative isolate text-center overflow-hidden px-6 py-24 sm:px-16">
      <img width="105.4" height="176"  src="/assets/svg/Vestra_BlackTrSVG.svg" alt="Solution pour mes invendus" class="h-64 w-auto mx-auto">
<!--      <p class="text-5xl font-normal">Vestra</p>-->
    </div>
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
        <h1 class="max-w-2xl text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl lg:col-span-2 xl:col-auto">La solution pour vos invendus</h1>
        <div class="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
          <p class="text-lg leading-8 text-gray-600">Vestra est la première plateforme internet en Suisse destinée aux magasins de sports, avec pour objectif de proposer une solution à la problématique des articles invendus.
            <br>
            Nous vous proposons un espace de vente, et nous nous occupons de promouvoir vos articles ainsi que votre magasin !</p>
        </div>
        <img width="1080" height="941" src="/assets/img/91374.webp" alt="Déstockage de marques en Suisse" class="w-full max-w-lg rounded-2xl object-cover lg:max-w-none xl:row-span-2 xl:row-end-2">
      </div>
    </div>
    <div class="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-stone-100 sm:h-32"></div>
  </div>

  <!-- partenaires -->
  <div id="partenaire" class="mx-auto mt-32 max-w-7xl sm:mt-40 sm:px-6 lg:px-8">
    <div class="relative isolate text-center overflow-hidden bg-stone-500 px-6 py-24 shadow-2xl sm:rounded-3xl sm:px-16">
      <h2 class="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl">Inscrivez dès maintenant votre magasin sur Vestra</h2>
      <p class="mx-auto  mt-6 max-w-xl text-base leading-6 text-gray-300">En tant que magasins partenaires,
        vous pourrez y écouler vos invendus de manière autonome. Nous nous chargeons de votre visibilité. Vous amenez les produits, nous amenons les clients.
      </p>
      <p class="mt-10 mx-auto items-center gap-x-6">
        <a href="{{environment.retailerUrl}}" class="mr-6 rounded-md bg-stone-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-stone-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600">S'inscrire</a>
        <a (click)="openPopup()" class="text-white text-sm font-semibold leading-6 text-gray-900">Prendre contact <span aria-hidden="true">→</span></a>
      </p>


      <div class="absolute -top-24 right-0 -z-10 transform-gpu blur-3xl" aria-hidden="true">
        <div class="aspect-[1404/767] w-[87.75rem] bg-gradient-to-r from-[#78716c] to-[#44403c] opacity-25" style="clip-path: polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)"></div>
      </div>
    </div>
  </div>



  <div class="mx-auto max-w-7xl px-6 py-24 sm:pt-32 lg:px-8 lg:py-40">
    <div class="lg:grid lg:grid-cols-12 lg:gap-8">
      <div class="lg:col-span-5">
        <h2 class="text-2xl font-bold leading-10 tracking-tight text-gray-900">Comment ça marche ?</h2>
        <p class="mt-4 text-base leading-7 text-gray-600">Pour vendre un de vos articles sur Vestra, rien de plus simple.</p>
      </div>
      <div class="mt-10 lg:col-span-7 lg:mt-0">
        <dl class="space-y-10">
          <div>
            <dt class="text-base font-semibold leading-7 text-gray-900"></dt>
            <dd class="mt-2 text-base leading-7 text-gray-600">
              <ol class="list-decimal ml-4">
                <li>Connectez-vous à votre compte Vestra</li>
                <li>Sélectionnez l’article que vous souhaitez proposer sur la plateforme Vestra et choisissez le prix de vente
                </li>
                <li>Le client passe chercher dans votre magasin l’article qu’il a réservé
                </li>
                <li>Encaissez le montant de la vente en cash ou par le biais de la plateforme Vestra</li>
              </ol>
              <div class="mt-10 flex items-center gap-x-6">
                <a href="{{environment.retailerUrl}}" class="rounded-md bg-stone-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-stone-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600">S'inscrire</a>
                <a (click)="openPopup()" class="text-sm font-semibold leading-6 text-gray-900">Prendre contact <span aria-hidden="true">→</span></a>
              </div>
            </dd>
          </div>

          <!-- More questions... -->
        </dl>
      </div>
    </div>
  </div>


  <div class="py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:text-center">
        <h2 class="text-base font-semibold leading-7 text-stone-600">Nos services</h2>
        <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Nous développons Vestra pour vous</p>
        <p class="mt-6 text-lg leading-8 text-gray-600">Vos besoins sont au coeur de nos préocupations.</p>
      </div>
      <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
        <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
          <div class="flex flex-col">
            <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z" />
              </svg>
              Intégration sur mesure
            </dt>
            <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
              <p class="flex-auto">des systèmes de caisses et des stocks des magasins indépendants</p>
              <!--              <p class="mt-6">-->
              <!--                  <a href="#" class="text-sm font-semibold leading-6 text-stone-600">En savoir plus <span aria-hidden="true">→</span></a> -->
              <!--              </p>-->
            </dd>
          </div>
          <div class="flex flex-col">
            <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
              <svg class="h-5 w-5 flex-none text-stone-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z" clip-rule="evenodd" />
              </svg>
              Mises à jour
            </dt>
            <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
              <p class="flex-auto">des fonctionnalités en fonction des besoins des magasins</p>
              <!--              <p class="mt-6">-->
              <!--                  <a href="#" class="text-sm font-semibold leading-6 text-stone-600">En savoir plus <span aria-hidden="true">→</span></a> -->
              <!--              </p>-->
            </dd>
          </div>
          <div class="flex flex-col">
            <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 1 1 0-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 0 1-1.44-4.282m3.102.069a18.03 18.03 0 0 1-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 0 1 8.835 2.535M10.34 6.66a23.847 23.847 0 0 0 8.835-2.535m0 0A23.74 23.74 0 0 0 18.795 3m.38 1.125a23.91 23.91 0 0 1 1.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 0 0 1.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 0 1 0 3.46" />
              </svg>


              Campagnes marketing
            </dt>
            <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
              <p class="flex-auto">afin de trouver des acheteurs pour vos invendus</p>
<!--              <p class="mt-6">-->
<!--                  <a href="#" class="text-sm font-semibold leading-6 text-stone-600">En savoir plus <span aria-hidden="true">→</span></a> -->
<!--              </p>-->
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>


  <div class="relative isolate overflow-hidden py-16 sm:py-24 lg:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
        <div class="max-w-xl lg:max-w-lg">
          <h2 class="text-3xl font-bold tracking-tight sm:text-4xl text-gray-900">Logiciels partenaires</h2>
          <p class="mt-4 text-lg leading-8 text-gray-600">Afin de fluidifier la gestion des stocks entre les magasins et la plateforme Vestra, nous avons construit des partenariats avec différents fournisseurs de logiciels de caisse.
            <br><br>
            Nous pouvons à présent supporter les logiciels de caisse suivants.

            Pour télécharger les APIs, veuillez vous inscrire comme magasin partenaire</p>


          <div class="mt-10 flex items-center gap-x-6">
            <a href="{{environment.retailerUrl}}" class="rounded-md bg-stone-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-stone-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600">S'inscrire</a>
            <a (click)="nav.goTo('/api')" class="text-sm font-semibold leading-6 text-gray-900">Qu’est-ce qu’une API ? <span aria-hidden="true">→</span></a>
          </div>
        </div>
        <dl class="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
          <div class="flex flex-col items-start">
            <a href="http://www.bestinfo.ch/bcPRISME.html" target="_blank">
            <div class="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
              <svg class="h-6 w-6 " fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 21v-7.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349M3.75 21V9.349m0 0a3.001 3.001 0 0 0 3.75-.615A2.993 2.993 0 0 0 9.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 0 0 2.25 1.016c.896 0 1.7-.393 2.25-1.015a3.001 3.001 0 0 0 3.75.614m-16.5 0a3.004 3.004 0 0 1-.621-4.72l1.189-1.19A1.5 1.5 0 0 1 5.378 3h13.243a1.5 1.5 0 0 1 1.06.44l1.19 1.189a3 3 0 0 1-.621 4.72M6.75 18h3.75a.75.75 0 0 0 .75-.75V13.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75Z" />
              </svg>
            </div>
            <dt class="mt-4 font-semibold text-gray-900">BC Prisme</dt>
<!--            <dd class="mt-2 leading-7 text-gray-600">Non laboris consequat cupidatat laborum magna. Eiusmod non irure cupidatat duis commodo amet.</dd>-->
          </a>
          </div>


          <div class="flex flex-col items-start">
            <a href="https://www.cashflow.ch/" target="_blank">
            <div class="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
              <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 21v-7.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349M3.75 21V9.349m0 0a3.001 3.001 0 0 0 3.75-.615A2.993 2.993 0 0 0 9.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 0 0 2.25 1.016c.896 0 1.7-.393 2.25-1.015a3.001 3.001 0 0 0 3.75.614m-16.5 0a3.004 3.004 0 0 1-.621-4.72l1.189-1.19A1.5 1.5 0 0 1 5.378 3h13.243a1.5 1.5 0 0 1 1.06.44l1.19 1.189a3 3 0 0 1-.621 4.72M6.75 18h3.75a.75.75 0 0 0 .75-.75V13.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75Z" />
              </svg>
            </div>
            <dt class="mt-4 font-semibold text-gray-900">CashFlow</dt>
<!--            <dd class="mt-2 leading-7 text-gray-600">Officia excepteur ullamco ut sint duis proident non adipisicing. Voluptate incididunt anim.</dd>-->
            </a>
          </div>
        </dl>
      </div>
    </div>
    <div class="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6" aria-hidden="true">
      <div class="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#78716c] to-[#e7e5e4] opacity-30" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"></div>
    </div>
  </div>







