import { Injectable } from '@angular/core';
import {Router} from "@angular/router";
import {ShopMenuService} from "./shop-menu.service";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  constructor(private router: Router) { }

  goTo(href: any) {
    this.router.navigate([href]);
  }
  goToHome() {
    this.router.navigate(['/'])
  }

  goToLink(string: string) {
    this.router.navigate([string]);
  }

  goToArticle(article_id: any) {
    this.router.navigate(['/article/' + article_id]);
  }

  /**
   * go to shop when first param is NOT '/shop/'
   * @param params
   */
  goToShop(params?: string) {
    this.router.navigate(['/shop/' + params]);
  }

  /**
   * go to shop when first param IS '/shop/'
   * @param params
   */
  goToShopBreadCrumb(params : string) {
    if(params === 'shop') {
      this.router.navigate([params]);
    } else {
      console.log(params)
      this.router.navigate([decodeURIComponent(params)]);
    }
  }

  // addCategory(params: string) {
  //   this.goToShop(this.router.url + '/' + params)
  // }

  goToCheckout() {
    this.router.navigate(['/checkout/']);
  }

  goToResetPassword() {
    this.router.navigate(['/reset-password/']);
  }
  
  preventDefault(event :MouseEvent){
    event.preventDefault();
  }

  goToSuccessPayment() {
    this.router.navigate(['/summary/'])
  }

  goToOrderDetail(id: string) {
    this.router.navigate(['/order-detail/' + id])
  }
  goToMyOrders() {
    this.router.navigate(['/user/orders'])
  }

  goToMyProfile() {
    this.router.navigate(['/user/profile'])
  }
  goToMyProfileSettings() {
    this.router.navigate(['/user/security'])
  }
  goToLogin() {
    this.router.navigate(['/login'])
  }

  goToUserDetailOrder(id: string) {
    this.router.navigate(['/user/order-details/'+ id])
  }

  goToCheckEmail(email: string) {
    this.router.navigate(['/check-email/' + email])
  }

  goToDeleteAccount() {
    this.router.navigate(['/user/delete-account/'])
  }

  goToChart() {
    this.router.navigate(['/cart/'])
  }



}
