import { Component } from '@angular/core';


@Component({
  selector: 'app-full-order-details',
  templateUrl: './full-order-details.component.html',
  styleUrls: ['./full-order-details.component.scss']
})
export class FullOrderDetailsComponent {
  order = {
    id : 24373402
  }
  downloadPdf() {
    // Get the content of the div
    const divContent = document.getElementById("my-pdf")!.innerHTML;

    // Create an invisible iframe
    const iframe = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.width = '0px';
    iframe.style.height = '0px';
    iframe.style.border = 'none';

    document.body.appendChild(iframe);

    // Get the iframe document
    const iframeDoc = iframe.contentWindow?.document;
    if (iframeDoc) {
      // Copy the current page styles to the iframe
      const styleSheets = document.styleSheets;
      let styles = '';
      for (let i = 0; i < styleSheets.length; i++) {
        try {
          const rules = styleSheets[i].cssRules || styleSheets[i].rules;
          for (let j = 0; j < rules.length; j++) {
            styles += rules[j].cssText + '\n';
          }
        } catch (e) {
          console.warn("Could not access stylesheet", styleSheets[i], e);
        }
      }

      // Write the content and styles to the iframe
      iframeDoc.open();
      iframeDoc.write(`
                    <html>
                    <head><style>
                       <script src="https://cdn.tailwindcss.com"></script>
                      ${styles}</style><title>commande_numéro_${this.order.id}</title></head>
                    <body>${divContent}</body>
                    </html>
                `);
      iframeDoc.close();

      // Deactivate all links in the iframe
      const links = iframeDoc.querySelectorAll('a');
      links.forEach(link => {
        link.removeAttribute('href');  // Remove the href attribute
        link.style.pointerEvents = 'none';  // Disable pointer events
        link.style.color = 'black';  // Optional: change link color to look like regular text
        link.style.textDecoration = 'none';  // Optional: remove underline
      });

      // Trigger the print dialog and remove the iframe after printing
      iframe.contentWindow?.focus();
      iframe.contentWindow?.print();
      document.body.removeChild(iframe);
    }


    // // Get the content of the div
    // const divContent = document.getElementById("my-pdf")!.innerHTML;
    //
    // // Create a Blob object representing the data as a PDF-like file
    // const blob = new Blob([divContent], { type: 'application/pdf' });
    //
    // // Create an object URL for the Blob
    // const url = URL.createObjectURL(blob);
    //
    // // Create a link element
    // const a = document.createElement('a');
    // a.href = url;
    // a.download = 'downloaded-file.pdf'; // Filename for the downloaded file
    //
    // // Programmatically click the link to trigger the download
    // document.body.appendChild(a);
    // a.click();
    //
    // // Clean up and revoke the object URL
    // setTimeout(() => {
    //   document.body.removeChild(a);
    //   URL.revokeObjectURL(url);
    // }, 0);

  }


}
