import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BffApiService} from "../../../shared/api/bff-api.service";

@Component({
  selector: 'app-page-reset-password',
  templateUrl: './page-reset-password.component.html',
  styleUrls: ['../connexion.scss']
})
export class PageResetPasswordComponent implements OnInit {
  reset_pwd_form: FormGroup = this.fb.group({
    email: [null, Validators.required],
  })

  constructor(private fb: FormBuilder,
              private bff: BffApiService,) {
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.reset_pwd_form = this.fb.group({
      email: [null, Validators.required],
    })
  }

  submit() {
    this.bff.login(this.reset_pwd_form.value).subscribe(data => console.log(data))
    console.log(this.reset_pwd_form.value);
  }
}
