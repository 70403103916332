import {CanActivateFn, Router} from '@angular/router';
import {UserService} from "./user.service";
import {inject} from "@angular/core";
import {Role} from "../../../../../fe-global-components/interfaces/enums";

export const authGuard: CanActivateFn = async (route, state) => {
  const router: Router = inject(Router);
  const userService: UserService = inject(UserService);

  const userRole: Role = await userService.getProfilInfos();
  const expectedRoles: Role[] = route.data['roles'];
  const hasRole: boolean = expectedRoles.some((role) => userRole === role);

  return hasRole || router.navigate(['login']);
};
