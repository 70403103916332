<div class="border-b border-gray-200 py-6">
  <h3 class="-my-3 flow-root">
    <!-- Expand/collapse section button -->
    <button type="button" class="flex w-full items-center justify-between  py-3 text-sm text-gray-400 hover:text-gray-500" aria-controls="filter-section-2" aria-expanded="false">
      <span class="font-medium text-gray-900">{{category.name}}</span>
      <app-plus-minus (click)="toggle_category()"  [isOpen]="isOpen"></app-plus-minus>
    </button>
  </h3>
  <!-- Filter section, show/hide based on section state. -->
  <div *ngIf="isOpen" @opacity class="pt-6" id="filter-section-2">
    <div class="space-y-4">
      <div *ngFor="let value of category.values" class="flex items-center">
        <input id="filter-size-0" name="{{value.input_name}}" value="{{value.value}}" (change)="toggleFilter($event, category.name, value)" type="checkbox" class="checkbox">
        <label for="filter-size-0" class="check-label-comp">{{value.name}}</label>
      </div>
    </div>
  </div>
</div>
