<header class="bg-white absolute inset-x-0 top-0">
  <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
    <div class="flex lg:flex-1">
      <a href="/" (click)="nav.goToHome(); nav.preventDefault($event)" class="-m-1.5 p-1.5">
        <span class="sr-only">Vestra</span>
        <img class="h-8 w-auto" src="../../../assets/svg/logo.svg" alt="vestra-logo">
      </a>
    </div>
    <div class="flex lg:hidden">
      <app-user-avatar class="px-3"></app-user-avatar>

      <button (click)="toggleBasket()" class="group -m-2 inline-flex items-center p-2 px-3">
        <svg class="h-6 w-6 flex-shrink-0 text-gray-900 group-hover:text-gray-500" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
        </svg>
        <span class="ml-0.5 text-sm font-medium text-gray-700 group-hover:text-gray-800">{{numberItemInBag}}</span>
        <span class="sr-only">items in cart, view bag</span>
      </button>

      <button (click)="toggleGeneralMenu()" type="button" class="inline-flex items-center justify-center rounded-md mx-2.5 text-gray-700">
        <span class="sr-only">Open main menu</span>
        <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
        </svg>
      </button>
    </div>
    <div class="hidden lg:flex lg:gap-x-12">
      <a *ngFor="let menu of menu_links" href="{{menu.href}}" (click)="nav.goTo(menu.href); nav.preventDefault($event); ">{{menu.name}}</a>
    </div>
    <div class="hidden lg:flex lg:flex-1 lg:justify-end gap-x-4">
      <!--      <a href="/login">Login</a>-->
      <!--      <a href="/logout">Logout</a>-->
      <!--      <button mat-button [matMenuTriggerFor]="menu">FR</button>-->
      <!--      <mat-menu #menu="matMenu">-->
      <!--        <button mat-menu-item>FR</button>-->
      <!--        <button mat-menu-item>DE</button>-->
      <!--        <button mat-menu-item>IT</button>-->
      <!--      </mat-menu>-->
      <app-user-avatar></app-user-avatar>
      <!-- Cart -->
      <div class="ml-4 flow-root lg:ml-6">
        <a (click)="toggleBasket()" class="group -m-2 flex items-center p-2">
          <svg class="h-6 w-6 flex-shrink-0 text-gray-900 group-hover:text-gray-500" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
          </svg>
          <span class="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">{{numberItemInBag}}</span>
          <span class="sr-only">items in cart, view bag</span>
        </a>
      </div>

    </div>
  </nav>
  <!-- Mobile menu, show/hide based on menu open state. -->
  <div @translateX *ngIf='mobilMenuIsOpen'  role="dialog" aria-modal="true">
    <!-- Background backdrop, show/hide based on slide-over state. -->
    <div class="fixed inset-0 z-40"></div>
    <div class="fixed bg-stone-50 inset-y-0 right-0 z-40 w-full overflow-y-auto px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
      <div class="flex items-center justify-between">
        <a href="/" (click)="nav.goToHome(); nav.preventDefault($event)" class="-m-1.5 p-1.5">
          <span class="sr-only">Vestra</span>
          <img class="h-8 w-auto" src="assets/svg/logo.svg" alt="Vestra">
        </a>
        <button (click)="toggleGeneralMenu()" type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700">
          <span class="sr-only">Close menu</span>
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="mt-6 flow-root">
        <div class="-my-6 divide-y divide-gray-500/10">
          <div class="space-y-2 py-6">
            <a class="-mx-3 block rounded-lg px-3 py-2 text-base leading-7 text-gray-900 hover:bg-stone-50"
               *ngFor="let menu of menu_links" href="{{menu.href}}" (click)="toggleGeneralMenu(); nav.goTo(menu.href); nav.preventDefault($event)">{{menu.name}}</a>
          </div>
          <div class="space-y-2 py-6">
            <a class="-mx-3 block rounded-lg px-3 py-2 text-base leading-7 text-gray-900 hover:bg-stone-50"
               (click)="toggleBasket()">Panier</a>
          </div>
          <!--          <div class="py-6">-->
          <!--            <a class="-mx-3 block rounded-lg px-3 py-2 text-base leading-7 text-gray-900 hover:bg-stone-50"-->
          <!--              *ngFor="let user_menu of user_menu_links" href="{{user_menu.href}}">{{user_menu.name}}</a>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</header>

<app-shopping-nav class="sticky-menu" *ngIf="this.displayShopMenu"></app-shopping-nav>


<app-cart-component class="" [(cartOpen)]="isCartOpen"></app-cart-component>
