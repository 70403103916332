import { Component } from '@angular/core';
import {LoaderService} from "../loader.service";
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-loading-component',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms ease-out', style({  opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('150ms ease-in', style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class LoadingComponent {
  constructor(public loader: LoaderService) { }
}
