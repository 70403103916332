import {Component, Input} from '@angular/core';
import {Product} from "../../../../../../fe-global-components/interfaces/interfaces";
@Component({
  selector: 'app-preview-article',
  templateUrl: './preview-article.component.html',
  styleUrls: ['./preview-article.component.scss']
})
export class PreviewArticleComponent {
 @Input() index: number = 0
 // @ts-ignore
  @Input() product: Product;

  constructor() {}

}
