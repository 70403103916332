import { Component } from '@angular/core';
import {NavigationService} from "../../../shared/services/navigation.service";
import {Order} from "../../../../../../fe-global-components/interfaces/interfaces";
import {orders} from "../../../../../../fe-global-components/mockup_data/mockup_data";

@Component({
  selector: 'app-overview-orders',
  templateUrl: './overview-orders.component.html',
  styleUrls: ['./overview-orders.component.scss']
})
export class OverviewOrdersComponent {
  orders: Order[] = orders;
  constructor(protected nav: NavigationService) {
  }

  protected readonly print = print;

  saveAsPDF() {
    // TODO save as pdf
  }
}
