import {Component} from '@angular/core';
import {product} from "../../../../../fe-global-components/mockup_data/mockup_data";

@Component({
  selector: 'app-img-carousel',
  templateUrl: './img-carousel.component.html',
  styleUrls: ['./img-carousel.component.scss']
})
export class ImgCarouselComponent {


  protected readonly product = product;
}
