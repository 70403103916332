import { Component, OnInit } from '@angular/core';
import { CartService } from "../../../shared/services/cart.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NavigationService } from "../../../shared/services/navigation.service";
import { Color, Product, Size } from "../../../../../../fe-global-components/interfaces/interfaces";
import { colors, product, sizes, colorsDic, sizesDic } from "../../../../../../fe-global-components/mockup_data/mockup_data";
import { BffApiService } from "../../../shared/api/bff-api.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-page-one-article',
  templateUrl: './page-one-article.component.html',
  styleUrls: ['./page-one-article.component.scss']
})
export class PageOneArticleComponent implements OnInit {
  product: Product = product;

  // TODO refactor with app-quick-view
  colors: Color[] = colors;
  sizes: Size[] = sizes;
  currentColor: Color = this.colors[0];
  currentSize: Size = this.sizes[1];
  // TODO END refactor with app-quick-view

  article_form: FormGroup = this.fb.group({
    size: [this.colors[0].name, Validators.required],
    color: [this.sizes[1].name, Validators.required],
  })
  constructor(protected cartService: CartService,
    private route: ActivatedRoute,
    private bff: BffApiService,
    private fb: FormBuilder,
    public nav: NavigationService) { }

  getUniqueColors() { // TODO 
    // let uniqueColors: Color[] = [];
    // this.product.variants.forEach(v => {
    //   // Check if an element with the same size already exists
    //   if (!uniqueColors.some(color => color.name === v.color.name)) {
    //     uniqueColors.push(v.color);
    //   }
    // });
    // if (uniqueColors.length > 0 ) {
    //   this.colors = []
    //   uniqueColors.forEach(c => this.colors.push(colorsDic[c.name as keyof typeof colorsDic]))
    // }
    // this.currentColor = this.colors[0];
  }

  getUniqueSizes() {
    const uniqueSizes: any[] = [];
    this.product.variants.forEach(v => {
      // Check if an element with the same size already exists
      if (!uniqueSizes.some(size => size.name === v.size.name)) {
        uniqueSizes.push(v.size);
      }
    });
    if (uniqueSizes.length > 0) {
      this.sizes = []
      uniqueSizes.forEach(c => this.sizes.push(sizesDic[c.value as keyof typeof sizesDic]))
    }
    // this.sizes = uniqueSizes;
    this.currentSize = this.sizes[1];
  }

  ngOnInit(): void {
    let productID = this.route.snapshot.paramMap.get('id')
    if (productID) {
      this.bff.getProducts('[id][$eq]=' + productID).subscribe(res => {
        this.product = res[0]
        this.initForm()
      })
    }
    else {
      this.initForm()
    }
  }

  initForm() {
    this.getUniqueSizes();
    this.getUniqueColors();
    this.article_form = this.fb.group({
      size: [this.colors[0].name, Validators.required],
      color: [this.sizes[1].name, Validators.required],
    })
  }

  // TODO refactor with app-quick-view
  selectColor(color: Color) {
    if (color.available) {
      this.currentColor = color;
    }
  }
  selectSize(size: Size) {
    if (size.available) {
      this.currentSize = size;
    }
  }
  // TODO END refactor with app-quick-view
}
