import { Component } from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {environment} from "../../../environments/environment";
import {NavigationService} from "../../shared/services/navigation.service";
import {PopupComponent} from "../../component/popup-components/popup/popup.component";

@Component({
  selector: 'app-page-commerce',
  templateUrl: './page-commerce.component.html',
  styleUrl: './page-commerce.component.scss'
})
export class PageCommerceComponent {

  constructor(private fb: FormBuilder,
              protected nav: NavigationService,
              private _snackBar: MatSnackBar,
              public dialog: MatDialog) {}


  protected readonly environment = environment;

  openPopup() {
    const dialogRef = this.dialog.open(PopupComponent);

    dialogRef.afterClosed().subscribe(result => {});
  }
}
