<main class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8 my-52 max-w-screen-md mx-auto">
  <div class="text-center">

      <div class="rounded-full w-16 h-16 bg-stone-600 text-stone-600 flex justify-center items-center select-none p-2 m-2 mx-auto">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-9 h-9 fill-stone-400">
          <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
        </svg>
      </div>



    <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Vérifiez votre adresse email</h1>
    <p class="mt-6 text-base leading-7 text-gray-600">Nous avons envoyé un lien de vérification à {{email}}<br><br>
      Cliquez sur le lien pour terminer la procédure de vérification.<br>
      Il se peut que vous deviez vérifier votre dossier spam.</p>
    <div class="mt-10 flex items-center justify-center gap-x-6">
      <a (click)="this.sendEmail()" class="rounded-md bg-stone-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-stone-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600">renvoyer l'email</a>
      <a (click)="this.nav.goToHome()" target="_blank" class="text-sm font-semibold text-gray-900">Page d'accueil <span aria-hidden="true">&rarr;</span></a>
    </div>
    <p class="mt-6 text-sm leading-7 text-gray-400"><a target="_blank" ref="mailto:contact@exe-cute.ch?subject=VérificationEmail">Vous pouvez nous contacter si vous avez des questions.</a></p>
  </div>
</main>
