import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NavigationService} from "../../shared/services/navigation.service";
import {CartService} from "../../shared/services/cart.service";
import {Article, Basket} from "../../../../../fe-global-components/interfaces/interfaces";
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-cart-component',
  templateUrl: './cart-component.component.html',
  styleUrls: ['./cart-component.component.scss'],
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms ease-in-out', style({  opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('500ms ease-in-out', style({ opacity: 0 }))
      ])
    ]),
    trigger('translateY', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('500ms ease-in-out', style({  transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('500ms ease-in-out', style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class CartComponentComponent implements OnInit {
  cart: Basket = { id: 0, total: 0.00, articles: [] };
  @Input() cartOpen!: boolean;
  @Output() cartOpenChange = new EventEmitter<boolean>();

  constructor(protected nav: NavigationService,
              private cartService: CartService) {
  }

  ngOnInit(): void {
    this.cartService.currentCart.subscribe(currentCart => this.cart = currentCart);
  }

  toggle_basket() {
    this.cartOpen = !this.cartOpen;
    this.cartOpenChange.emit(this.cartOpen)
  }

  goToCart() {
    this.toggle_basket();
    this.nav.goToChart()
  }

  removeArticle(selected_article: Article) {
    this.cartService.removeArticle(selected_article);
  }

  changingQuantity(event: any, article: Article) {
    this.cartService.changeQuantity(article, event.target!['value']);
  }



}
