import { Component } from '@angular/core';
import { animate, style, transition, trigger } from "@angular/animations";
import { NavigationService } from "../../shared/services/navigation.service";
import { ShopMenuService } from "../../shared/services/shop-menu.service";
import { sorts_articles } from "../../../../../fe-global-components/constants/shop-sort-articles";
import {
  Color,
  Link,
  QuickLinks,
  Section,
  Size
} from "../../../../../fe-global-components/interfaces/interfaces";
import { colors, products, sizes } from "../../../../../fe-global-components/mockup_data/mockup_data";

@Component({
  selector: 'app-page-search-article',
  templateUrl: './page-search-article.component.html',
  animations: [
    trigger('opacityLong', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms linear', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms linear', style({ opacity: 0 }))
      ])
    ]),
    trigger('translateXNeg', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in-out', style({ transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('300ms ease-in-out', style({ transform: 'translateX(-100%)' }))
      ])
    ]),
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('150ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('translateX', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-in-out', style({ transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('300ms ease-in-out', style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class PageSearchArticleComponent {
  pageName!: string | null;
  protected products = products;
  display_sort: boolean = false;

  protected readonly colors: Color[] = colors;
  protected readonly sizes: Size[] = sizes;
  categories: Section[] | QuickLinks[] | Link[] | [] = [];

  sorts_articles: any[] = sorts_articles;

  constructor(protected nav: NavigationService,
    private shopMenu: ShopMenuService) {
    this.shopMenu.currentCategories.subscribe(categories => {
      this.categories = categories[0]
      this.pageName = categories[1]
    })
    this.shopMenu.currentProducts.subscribe(products => this.products = products)

    console.log(colors)
    console.log(sizes)
  }

  toggle_sort() {
    this.display_sort = !this.display_sort
  }

  getSort(sort: any) {
    this.toggle_sort()
    this.nav.goToShop(sort.name)
  }

  isOffCanvasMenu = false;
  isOffCanvasMenuDialog = false;
  isOffCanvasFiltersMenu = false;
  isOffCanvasFiltersMenuDialog = false;

  menuStates: any = {
    isSortMenu: false,
    isCategoryMenu: false,
    isBrandMenu: false,
    isColorMenu: false,
    isSizeMenu: false,
  }

  toggleOffCanvasFiltersMenu() {
    this.isOffCanvasFiltersMenu = !this.isOffCanvasFiltersMenu;

    if (this.isOffCanvasFiltersMenuDialog) {
      setTimeout(() => {
        this.isOffCanvasFiltersMenuDialog = !this.isOffCanvasFiltersMenuDialog;
      }, 400)
    } else {
      this.isOffCanvasFiltersMenuDialog = !this.isOffCanvasFiltersMenuDialog;
    }
  }

  closeMenus() {
    Object.keys(this.menuStates).forEach(k => {
      this.menuStates[k] = false;
    })
  }

  toggleSortMenu() {
    const temp = !this.menuStates.isSortMenu;
    this.closeMenus();
    this.menuStates.isSortMenu = temp;
  }

  toggleCategoryMenu() {
    const temp = !this.menuStates.isCategoryMenu;
    this.closeMenus();
    this.menuStates.isCategoryMenu = temp;
  }

  toggleColorMenu() {
    const temp = !this.menuStates.isColorMenu;
    this.closeMenus();
    this.menuStates.isColorMenu = temp;
  }

  toggleSizeMenu() {
    const temp = !this.menuStates.isSizeMenu;
    this.closeMenus();
    this.menuStates.isSizeMenu = temp;
  }

}
