import { Component } from '@angular/core';
import {SideNavService} from "../../shared/services/side-nav.service";
import {NavigationEnd, NavigationStart, Router} from "@angular/router";
import {CartService} from "../../shared/services/cart.service";
import {NavigationService} from "../../shared/services/navigation.service";
import {menu_links, user_menu_links} from "../../../../../fe-global-components/constants/menus-links";
import {BffApiService} from "../../shared/api/bff-api.service";
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-navigation-top',
  templateUrl: './navigation-top.component.html',
  styleUrls: ['./navigation-top.component.scss'],
  animations: [
    trigger('translateX', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-in-out', style({  transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('300ms ease-in-out', style({ transform: 'translateX(100%)' }))
      ])
    ]),
  ]
})
export class NavigationTopComponent {
  menu_links = menu_links
  displayShopMenu: boolean = false;
  numberItemInBag: number = 0;

  constructor(private router: Router,
              protected nav: NavigationService,
              private bff: BffApiService,
              private sideNavService: SideNavService,
              private cartService: CartService) {
    this.cartService.currentCart.subscribe(bag => this.numberItemInBag = bag.articles.length);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.displayShopMenu = !!(event.url && (event.url.includes('shop') || event.url.includes('article') ));
      }
      if(event instanceof NavigationEnd) {
        //TODO Hide loading indicator
        //console.log(event)
      }
    });
  }

  isCartOpen: boolean = false;
  mobilMenuIsOpen: boolean = false;

  toggleBasket () {
    this.isCartOpen = !this.isCartOpen;
  }

  toggleGeneralMenu() {
    this.mobilMenuIsOpen = !this.mobilMenuIsOpen
  }


}
