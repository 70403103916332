import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { NavigationService } from "../../shared/services/navigation.service";
import { SideNavService } from "../../shared/services/side-nav.service";
import { CartService } from "../../shared/services/cart.service";
import { Link, Section } from "../../../../../fe-global-components/interfaces/interfaces";
import { clothingSections, shortSection } from "../../../../../fe-global-components/constants/menu-shopping-links";
import { ShopMenuService } from "../../shared/services/shop-menu.service";

@Component({
  selector: 'app-shopping-nav',
  templateUrl: './shopping-nav.component.html',
  animations: [
    trigger('opacityLinear', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms linear', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms linear', style({ opacity: 0 }))
      ])
    ]),
    trigger('translateX', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in-out', style({ transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('300ms ease-in-out', style({ transform: 'translateX(-100%)' }))
      ])
    ]),
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('150ms ease-in', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class ShoppingNavComponent implements OnInit {
  clothingSections = clothingSections;
  shortSection = shortSection;

  isOffCanvasMenu = false;
  currentMenu: Section | undefined | null = null;
  currentTab: Section | undefined = this.clothingSections[0];

  isOffCanvasMenuDialog = false;
  numberItemInBag: number = 0;
  protected breadcrumb: Link[] = [];

  constructor(public nav: NavigationService,
    private sideNavService: SideNavService,
    private cartService: CartService,
    private shopMenu: ShopMenuService) { }
  ngOnInit(): void {
    this.cartService.currentCart.subscribe(bag => this.numberItemInBag = bag.articles.length);
    this.shopMenu.currentBreadcrumb.subscribe(breadcrumb => this.breadcrumb = breadcrumb)
  }
  toggle_basket() {
    this.sideNavService.toggle();
  }
  toggleOffCanvasMenu() {
    this.isOffCanvasMenu = !this.isOffCanvasMenu;

    if (this.isOffCanvasMenuDialog) {
      setTimeout(() => {
        this.isOffCanvasMenuDialog = !this.isOffCanvasMenuDialog;
      }, 400)
    } else {
      this.isOffCanvasMenuDialog = !this.isOffCanvasMenuDialog;
    }
  }
  selectCategory(category: string) {
    /*display the menu links */
    if (this.currentMenu?.name === category) {
      this.currentMenu = null;
    } else {
      this.currentMenu = this.clothingSections.find(section => section.name === category)
    }
  }
  selectTab(tab: string) {
    this.currentTab = this.clothingSections.find(section => section.name === tab)
  }

  gotToCategory(main: any, cat: any, link: any) {
    this.shopMenu.changeLink([main, cat, link])
  }


  navigateToCategory(bread: any) {
    this.shopMenu.navigateToBreadCrumb(bread)
  }

}
