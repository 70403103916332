import {Component} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  displayMainManu: boolean = true;

  // constructor(private router: Router) {
    // TODO not sure this is usefull, to be verified
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationStart) {
    //     this.displayMainManu = !!(event.url && !event.url.includes('/retailer2'));
    //   }
    // });
  // }


}
