<form class="hidden lg:block">
  <h3 class="sr-only">Categories</h3>
  <ul role="list" class="space-y-4 border-b border-gray-200 pb-6 text-sm font-medium text-gray-900">

    <li *ngFor="let category of categories">
      <a class="cursor-pointer" (click)="navigateToCategory(category)">{{category.name}}</a>
    </li>
  </ul>

  <app-search-category *ngFor="let category of search_categories;"
                       [category]="category"></app-search-category>
</form>
